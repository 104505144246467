<template>
    <div class="dropdown-content p-4">
        <h5 class="text-secondary-600 font-bold">Measurement Details</h5>

        <p
            v-for="(field, key) in fields"
            :key="key"
            class="px-2 pt-3"
            :class="{
                'text-green-600': field.value,
                'text-red-600': !field.value,
            }"
        >
            <span class="block">
                <font-awesome-icon
                    v-if="field.value"
                    :icon="['fas', 'check-circle']"
                ></font-awesome-icon>
                <font-awesome-icon
                    v-else
                    :icon="['fas', 'times']"
                ></font-awesome-icon>
                {{ field.label }}</span
            >
        </p>
    </div>
</template>

<script>
/**
 * @group MOLECULES
 * Wrapper for the dropdown content. Simply a div with list of links.
 */
import { mapGetters } from 'vuex'

export default {
    computed: {
        fields() {
            return [
                { value: this.getUUID, label: 'UUID created', route: '' },
                { value: this.hasFiles, label: 'Files ready', route: '' },
                {
                    value: this.hasCloudTask,
                    label: 'Cloud Task created',
                    route: '',
                },

                { value: this.hasResults, label: 'Files ready', route: '' },
                { value: !this.hasError, label: 'No Errors', route: '' },
                { value: this.isComplete, label: 'Completed', route: '' },
            ]
        },

        ...mapGetters('measurement', [
            'getUUID',
            'hasFiles',
            'hasCloudTask',
            'hasResults',
            'hasError',
            'isComplete',
        ]),
    },
}
</script>

<style lang="scss" scoped></style>
