<template>
    <div class="mt-8">
        <h3 class="text-xl text-primary-700 mb-2 pb-0">
            Overview of Measurements
        </h3>
        <div class="">
            <div
                v-if="measurements === null"
                class="bg-gray-200 rounded-lg max-w-sm p-4 text-gray-500 animate-pulse"
            >
                Loading...
            </div>

            <div
                v-else-if="measurements && !measurements.length"
                class="bg-gray-200 rounded-lg max-w-sm p-4 text-gray-500 "
            >
                <font-awesome-icon
                    :icon="['fas', 'folder-open']"
                    class="mr-2"
                />
                No measurements created yet.
            </div>

            <div v-else class="flex flex-wrap flex-row">
                <measurement-card
                    v-for="measurement in measurements"
                    :key="measurement.uuid"
                    :measurement="measurement"
                ></measurement-card>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'
/**
 * @group ORGANISMS
 * Lists the last 10 measurements of the patient vom Vuex method `getMeasurements()`
 *
 * Has a listener method that is callen beforeDestroy()
 */
export default {
    data() {
        return {
            listener: null,
        }
    },

    computed: {
        ...mapGetters('patient', {
            measurements: 'getMeasurements',
        }),
    },

    beforeDestroy() {
        // Detach the listener
        if (_.isFunction(this.listener)) {
            this.listener()
        }
    },
    mounted() {
        this.clearActive()
        this.startMeasurementsListener()
            .then(listener => {
                this.listener = listener
            })
            .catch(() => {
                // Error
                // Make Toast
                // this.$toast.error('Could not load patients measurements')
            })
    },

    methods: {
        ...mapActions('measurement', [
            'clearActive', // -> this.hasActivePatient
            'startMeasurementsListener',
        ]),
    },
}
</script>
