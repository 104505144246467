<template>
    <div>
        <v-popover
            offset="0"
            :open="dropdownPopoverShow"
            placement="bottom-start"
            @hide="dropdownPopoverShow = false"
        >
            <button
                type="button"
                class="tooltip-target btn text-xl leading-none px-2 py-2 rounded-lg border-secondary-600 text-secondary-600 border-2 hover:text-primary-600 hover:border-primary-600 transition-all duration-700 outline-none focus:outline-none"
                @click="togglePopover"
            >
                <template v-if="!dropdownPopoverShow">
                    <font-awesome-icon :icon="['fas', 'ellipsis-h']" />
                </template>

                <template v-else>
                    <font-awesome-icon :icon="['fas', 'circle']"
                /></template>
            </button>

            <template slot="popover">
                <div
                    class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-0 min-w-40"
                >
                    <router-link
                        v-for="item in menu"
                        :key="item.key"
                        :to="{
                            name: item.name,
                            params: { uuid: item.uuid },
                        }"
                        class="py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 no-underline text-lg hover:text-primary-600 hover:bg-primary-100"
                    >
                        {{ item.title }}
                    </router-link>
                </div>
            </template>
        </v-popover>
    </div>
</template>

<script>
/**
 * @group ATOMS
 * A button with a dropdown menu for a button using the `v-popover` component
 */
export default {
    name: 'DropdownButton',
    $inheritAttrs: true,
    props: {
        /**
         * The menu that should be display in the dropdown menu (title | name).
         * Example: ` menu: {
                export: { title: 'Export', name: 'Export' },
            },`
         */
        menu: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            dropdownPopoverShow: false,
        }
    },

    methods: {
        togglePopover() {
            this.dropdownPopoverShow = !this.dropdownPopoverShow
        },
    },
}
</script>

<style lang="scss" scoped>
.popover-inner {
    padding-top: 0px;
}
</style>
