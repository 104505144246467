import { debounceTimers } from 'helpers/constants'
import _ from 'lodash'

const AutocompleteMixin = {
    props: {
        maxItems: {
            type: Number,
            default: 8,
        },
    },

    data() {
        return {
            selectedIndex: -1,
            isLoading: false,
            numberOfHits: 0,
            searchResults: [],
        }
    },
    computed: {
        model() {
            return this.context.model
        },
        selection() {
            if (this.filteredOptions[this.selectedIndex]) {
                return this.filteredOptions[this.selectedIndex]
            }
            return false
        },
        filteredOptions() {
            if (Array.isArray(this.context.options) && this.context.model) {
                const isAlreadySelected = this.context.options.find(
                    option => option.value === this.context.model
                )
                if (!isAlreadySelected) {
                    return this.context.options.filter(option =>
                        option.label
                            .toLowerCase()
                            .includes(this.context.model.toLowerCase())
                    )
                }
            }
            return []
        },
    },

    mounted: function() {
        document.addEventListener('click', this.onClick)
    },
    beforeDestroy: function() {
        document.removeEventListener('click', this.onClick)
    },

    methods: {
        // @vuese
        // Click Handler for UX Reasons
        onClick: function() {
            // Reset the result so that the box closes
            this.closeSearchbox()
        },
        // @vuese
        // Opens the result box with standard debounce on keyup
        openSearchbox: _.debounce(
            function(event) {
                // Do not query in case of Key is in NotAllowedKeys, so it does not infere with selecting a value
                const NotAllowedKeys = [
                    'Enter',
                    'ArrowDown',
                    'ArrowUp',
                    'Delete',
                ]

                if (!NotAllowedKeys.includes(event.key)) {
                    this.getSearchResult(this.context.model)
                }
            },
            debounceTimers.default,
            {
                maxWait: debounceTimers.maxWait,
            }
        ),
        // @vuese
        // Closes the result box and resets the inital values
        closeSearchbox() {
            this.searchResults = []
            this.selectedIndex = -1
        },

        // @vuese
        // On ENTER-event, either a value is selected (keyboard control) or a event is emitted to the parent
        enter() {
            // Set value of v-model
            if (this.selectedIndex >= 0) {
                this.selectEntry(this.selectedIndex)
            } else {
                this.closeSearchbox()
                // Fire enter so that parent component can listen
                this.$emit('enter')
            }
        },

        // @vuese
        // increases the selected value
        increment() {
            if (this.selectedIndex + 1 < this.searchResults.length) {
                this.selectedIndex++
            } else {
                this.selectedIndex = 0
            }
        },

        // @vuese
        // decreases the selected value
        decrement() {
            if (this.selectedIndex - 1 >= 0) {
                this.selectedIndex--
            } else {
                this.selectedIndex = this.searchResults.length - 1
            }
        },
    },
}

export default AutocompleteMixin
