<template>
    <div v-if="getUUID" class="meta-box w-full lg:w-1/4  my-5  flex-shrink-0">
        <h5 class="font-bold pb-2 text-secondary-700">
            Administration
        </h5>
        <div class="gray-box bg-gray-200 p-4 rounded-lg">
            <DeleteMeasurement :uuid="getUUID" />
        </div>
    </div>
</template>

<script>
/**
 * @group MOLECULES
 * A small toolbar for measurements. Currently only holds the DeleteMeasurement component.
 */
import { mapGetters } from 'vuex'

export default {
    inheritAttrs: true,

    computed: {
        ...mapGetters('measurement', ['getUUID']),
    },
}
</script>
