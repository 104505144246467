<template>
    <div
        class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-gray-500"
        :class="style"
        data-cypress="basic.notification"
    >
        <span class="text-xl inline-block mr-5 align-middle mb-4">
            <font-awesome-icon :icon="['fas', 'bell']" />
        </span>
        <span class="inline-block align-middle mr-8">
            <b class="capitalize"><slot name="bold"></slot></b>
            <!-- Text Content of the Alert -->
            <slot></slot>
        </span>
    </div>
</template>

<script>
/**
 * @group ATOMS
 * A notification to give feedback to the user. Similar to an alert. Alerts can be closed.
 */
export default {
    name: 'BasicNotification',
    inheritAttrs: true,
    props: {
        // The color type of the notification
        type: {
            // `'success'` / `'warning'`/ `'error'`/ `'info'`
            type: String,
            default: 'info',
        },
    },
    computed: {
        style() {
            /* eslint-disable no-unreachable */
            switch (this.type) {
                case 'error':
                    return 'text-red-800 bg-red-200'
                    break
                case 'success':
                    return 'text-green-800 bg-green-200'
                    break
                case 'warning':
                    return 'text-yellow-800 bg-yellow-200'
                    break
                default:
                    return 'text-white bg-grey-500'
            }
        },
    },
}
</script>
