/*
|-------------------------------------------------------------------------------
| VUEX index.js
|-------------------------------------------------------------------------------
| Builds the data store from all of the modules for app
*/
import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import user from './user'
import ui from './ui'
import patient from './patient'
import measurement from './measurement'
import settings from './settings'

// Plugins
import patientSubscriber from '@/store/plugins/patientSubscriber.js'

Vue.use(Vuex)

/*
  Adds the promise polyfill for IE 11
*/
//require('es6-promise').polyfill()

const store = new Vuex.Store({
    /*
     * STRICT MODE
     * ------
     * In strict mode, whenever Vuex state is mutated outside of mutation handlers, an error will be thrown.
     * This ensures that all state mutations can be explicitly tracked by debugging tools.
     * Its resource expensive and should only be enabled during dev.
     */
    // strict: process.env.NODE_ENV !== 'production',
    strict: false,

    /*
     * PLUGINS
     * ------
     */

    plugins: [patientSubscriber],

    /*
     * MODULES
     * ------
     */
    modules: {
        auth,
        user,
        ui,
        measurement,
        patient,
        settings,
    },
})
export default store
