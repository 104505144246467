/*
|-------------------------------------------------------------------------------
| VUEX MODULE
|-------------------------------------------------------------------------------
| Authentication Events
*/
import router from '@/router'
import { listAllActionsOfStore, listAllGettersOfStore } from 'helpers/common'

const mutations = {
    SHOW_ALERT: 'SHOW_ALERT',
    PREPARE_ALERT: 'PREPARE_ALERT',
    CLOSE_ALERT: 'CLOSE_ALERT',

    SHOW_MODAL: 'SHOW_MODAL',
    HIDE_MODAL: 'HIDE_MODAL',
    TOGGLE_MODAL: 'TOGGLE_MODAL',

    PREPARE_MODAL: 'PREPARE_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',

    SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
    SET_LOADING: 'SET_LOADING',
}

export const ui = {
    namespaced: true,

    state: {
        /**
         * status = 0 -> No loading has begun
         * status = 1 -> Loading has started
         * status = 2 -> Loading completed successfully
         * status = 3 -> Loading completed unsuccessfully
         *  */
        loadingStatus: 0,
        message: { type: null, text: null },
        alert: {
            text: null,
            type: null,
            show: false,
        },
        modal: {
            title: null,
            text: null,
            type: null,
            size: null,
            buttonText: null,
            toRoute: null,
            showCloseButton: null,
            show: false,
        },
    },

    mutations: {
        // Sets the modal
        [mutations.PREPARE_MODAL](state, modal) {
            state.modal = modal
        },

        // Show Modal
        [mutations.SHOW_MODAL](state) {
            state.modal.show = true
        },

        // Show Modal
        [mutations.TOGGLE_MODAL](state) {
            state.modal.show = !state.modal.show
        },

        // Hide Modal
        [mutations.HIDE_MODAL](state) {
            state.modal.show = false
        },

        // Set Loading Status
        [mutations.SET_LOADING](state, loadingStatus) {
            state.loadingStatus = loadingStatus
        },

        // Set Error Message
        [mutations.SET_ERROR_MESSAGE](state, errorMessage) {
            state.message = {
                type: 'error',
                message: errorMessage,
            }
        },
    },

    actions: {
        /** SHOW MODAL
         *
         *  Usage in other modules:
         *  dispatch('ui/setModal', {
            title: null,
            text: null,
            type: null,
            size: null,
            buttonText: null,
            toRoute: null,
            showCloseButton: null,
            show: false,
        }, { root: true })
         *  dispatch('ui/showModal',  { root: true })
         */
        showModal: {
            root: true,
            handler(namespacedContext) {
                namespacedContext.commit(mutations.SHOW_MODAL)
            },
        },

        setModal: {
            root: true,
            handler(namespacedContext, payload) {
                namespacedContext.commit(mutations.PREPARE_MODAL, payload)
            },
        },

        toggleModal: {
            root: true,
            handler(namespacedContext) {
                namespacedContext.commit(mutations.TOGGLE_MODAL)
            },
        },

        setLoading({ commit }, loadingStatus) {
            commit(mutations.SET_LOADING, loadingStatus)
        },

        setErrorMessage({ commit }, errorMessage) {
            commit(mutations.SET_ERROR_MESSAGE, errorMessage)
        },

        clearErrorMessage(commit) {
            commit(mutations.SET_ERROR_MESSAGE, null)
        },
    },

    getters: {
        showModal: state => state.modal.show,
        loading: state => state.loadingStatus === 1,
        error: state => state.message.type === 'error',
    },
}

listAllActionsOfStore(ui, 'ui')
listAllGettersOfStore(ui, 'ui')

export default ui
