import { mapState, mapActions, mapGetters } from 'vuex'
export default {
    props: {
        saveRoute: {
            type: String,
            default: null,
        },
        saveText: {
            type: String,
            default: 'Save',
        },
        backRoute: {
            type: String,
            default: null,
        },
        backText: {
            type: String,
            default: 'Back',
        },
    },

    computed: {
        ...mapGetters('patient', ['patientLoading']),
        ...mapState('patient', {
            currentPatient: state => state.currentPatient,
        }),
    },
    watch: {
        /**
         * When v-model change, set internal value.
         */
    },
    methods: {
        save() {
            // Save in DB
            this.savePatient()
                .then(() => {
                    // Emit an Event - A listener is implemented in the stepper component
                    this.$emit('next')

                    if (this.saveRoute) {
                        // Redirect
                        this.$router
                            .push({
                                name: this.saveRoute,
                                params: {
                                    patientId: this.currentPatient.patientId,
                                },
                            })
                            .catch(() => {})
                    }
                    this.$toast.success('Saved successfully.')
                })
                .catch(() => {
                    this.$toast.error(
                        'An error occured. Could not save patient.'
                    )
                })
        },

        back() {
            if (this.backRoute) {
                // Redirect
                this.$router
                    .push({
                        name: this.backRoute,
                        params: { patientId: this.currentPatient.patientId },
                    })
                    .catch(() => {})
            }
            // Emit Event
            this.$emit('back')
        },

        ...mapActions('patient', ['addPatientData', 'savePatient']),
    },
}
