// Icons
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faSpinner,
    faEnvelope,
    faKey,
    faBell,
    faSearch,
    faUser,
    faCheck,
    faCheckCircle,
    faTimes,
    faLock,
    faEllipsisH,
    faPlus,
    faCircle,
    faUserMd,
    faCogs,
    faSignOutAlt,
    faVial,
    faArrowRight,
    faFolderOpen,
    faClock,
    faCalendar,
    faArrowCircleLeft,
    faInfoCircle,
    faTrash,
    faPlusCircle,
    faMinusCircle,
    faArrowCircleRight,
    faArrowLeft,
    faCircleNotch,
    faSunrise,
    faSun,
    faSunset,
    faMoon,
    faUserSlash,
    faPills,
    faPollH,
    faWifiSlash,
    faEdit,
    faVials,
} from '@fortawesome/pro-solid-svg-icons'

library.add(
    faSpinner,
    faEnvelope,
    faKey,
    faBell,
    faSearch,
    faUser,
    faTimes,
    faLock,
    faEllipsisH,
    faPlus,
    faCircle,
    faUserMd,
    faCogs,
    faSignOutAlt,
    faVial,
    faCheckCircle,
    faCheck,
    faArrowRight,
    faFolderOpen,
    faClock,
    faCalendar,
    faArrowCircleLeft,
    faArrowCircleRight,
    faInfoCircle,
    faTrash,
    faPlusCircle,
    faMinusCircle,
    faArrowLeft,
    faCircleNotch,
    faSunrise,
    faSun,
    faSunset,
    faMoon,
    faUserSlash,
    faPills,
    faPollH,
    faWifiSlash,
    faEdit,
    faVials
)

export default FontAwesomeIcon
