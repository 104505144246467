//import { firebase } from '@/services/firebase'

export const emptyMeasurement = function() {
    return {
        // Hardcoded App for Showcase
        app: 'valproate',
        createdAt: null,
        currentStep: 1,
        deleted: false,
        completed: false,
        cloudTask: null,
        startedCloudTaskAt: null,
        files: { neg: null, pos: null },
        meta: {
            device: 'Test Value Device 123',
            country: 'CH',
        },
        conditions: {},
        results: { free: null, total: null, sideEffects: null, seizures: null },
        status: {
            code: 201,
            log: '',
            error: false,
            progress: 0,
            message: 'initalizing....',
        },
    }
}

export const emptyUpload = function() {
    return {
        pos: {
            status: 'not started',
            error: false,
            progress: 0,
            completed: false,
        },
        neg: {
            status: 'not started',
            error: false,
            progress: 0,
            completed: false,
        },
    }
}
