import store from 'store'
import patientStore from 'store/patient'
import { db } from '@/services/firebase'

export default function patient(from, to, next) {
    const { patientId } = to.params
    if (
        store.getters[patientStore.getterTypes.getCurrentPatient] === null &&
        patientId
    ) {
        store
            .dispatch(patientStore.actionTypes.checkInPatient, patientId)
            .then(() => {
                return next()
            })
    }

    return next({
        to: 'PatientCheckIn',
    })
}
