<template>
    <nav class="flex items-center content-between  bg-gray-200 py-2 sm:py-0">
        <div class="flex items-center text-white md:mr-10">
            <UnicolorLogo
                class="fill-current text-primary-700 w-32 px-4 sm:w-full h-auto sm:px-10 py-4"
                alt="Deep Breath Iniative"
                data-cypress="navbar.logo"
                @click="handleLogoClick"
            />
        </div>

        <div class="patient">
            <div class="text-base">
                <template v-if="hasActivePatient">
                    <div class="text-primary-500">
                        <router-link
                            :to="{
                                name: 'Dashboard',
                                params: { patientId: currentPatientId },
                            }"
                            class="no-underline"
                        >
                            <font-awesome-icon
                                v-if="patientLoading"
                                :icon="['fas', 'circle-notch']"
                                class="animate-spin text-secondary-300"
                                size="lg"
                            ></font-awesome-icon>
                            <font-awesome-icon
                                v-else
                                :icon="['fas', 'user']"
                                size="lg"
                            ></font-awesome-icon>
                            <span class="pl-2" data-cypress="navbar.user.name"
                                >{{ patient.firstname }} {{ patient.lastname }}
                            </span>
                        </router-link>
                    </div>
                </template>

                <template v-else>
                    <div class="text-gray-500">
                        <font-awesome-icon
                            :icon="['fas', 'user']"
                            size="lg"
                        ></font-awesome-icon>
                        <span class="pl-2">No active Patient</span>
                    </div>
                </template>
            </div>
        </div>

        <!-- Show Active Test -->
        <div v-if="hasActiveMeasurement" class="mx-8">
            <div class="text-primary-500">
                <v-popover offset="10">
                    <template slot="popover">
                        <MeasurementDropdown />
                    </template>
                    <a
                        class="tooltip-target no-underline cursor-pointer"
                        title="Show Details"
                    >
                        <font-awesome-icon
                            :icon="['fas', 'vial']"
                            size="lg"
                        ></font-awesome-icon>
                        <span class="tooltip-target pl-2">Valproate </span>
                    </a>
                </v-popover>
            </div>
        </div>
        <!-- / Show Active Test -->

        <div class="justify-end flex-1">
            <div class="ml-auto w-16">
                <v-popover offset="10">
                    <template slot="popover">
                        <NavbarDropdown :menu="userMenu" title="User" />
                    </template>
                    <a
                        class="tooltip-target no-underline cursor-pointer text-secondary-700"
                        title="User Menu"
                        data-cypress="navbar.userMenu.button"
                    >
                        <font-awesome-icon
                            :icon="['fas', 'user-md']"
                            size="2x"
                        ></font-awesome-icon>
                    </a>
                </v-popover>
            </div>
        </div>
    </nav>
</template>

<script>
import UnicolorLogo from '@/assets/img/UnicolorLogo.svg'
import { mapGetters, mapState, mapActions } from 'vuex'

/**
 * @group MOLECULES
 * Navbar with Logo, Patient, App and Dropdown Menu
 */
export default {
    components: {
        UnicolorLogo,
    },
    data() {
        return {
            // Menu Array with `title`, `routeName` and `icon?
            userMenu: [
                {
                    title: 'Settings',
                    routeName: 'Settings',
                    icon: 'cogs',
                    dataCypress: 'navbar.userMenu.settings.button',
                },
                {
                    title: 'Logout',
                    routeName: 'Logout',
                    icon: 'sign-out-alt',
                    dataCypress: 'navbar.userMenu.settings.logout',
                },
            ],
        }
    },
    computed: {
        ...mapState({
            patient: state => state.patient.currentPatient,
        }),
        ...mapGetters('patient', [
            'hasActivePatient',
            'currentPatientId', // -> this.hasActivePatient
            'patientLoading', // -> this.hasActivePatient
        ]),
        ...mapGetters('measurement', [
            'hasActiveMeasurement', // -> this.hasActivePatient
        ]),
    },

    created() {
        // Check for Patient ID
        const { patientId, uuid } = this.$route.params

        //  ID in Route but no checkin
        if (!this.hasActivePatient && patientId) {
            // Not Checked-In but Patient ID in URLS
            this.checkInPatient(patientId)
                .then(() => {
                    // OK
                    this.$toast.success(`Checked in Patient ${patientId}`)
                })
                .catch(err => {
                    this.$toast.error(err)
                })
        }

        // Check for inactive UUID in URL
        if (uuid && !this.hasActiveMeasurement) {
            this.loadMeasurement(uuid)
        }
    },

    methods: {
        // @vuese
        // Loads the measurement details for an active measurement
        // @arg uuid
        loadMeasurement(uuid) {
            this.getMeasurement(uuid)
                .then(() => {
                    // Do nothing, stay here
                    this.$toast.success(`Successfully Loaded Measurement`)
                })
                .catch(err => {
                    // Neither active measurement nor stored in DB
                    this.$toast.error(err)
                    // Redirect
                    this.$router.push({
                        name: 'Dashboard',
                        params: { patientId: this.currentPatientId },
                    })
                })
        },
        // @vuese
        // Handles clicks on the logo and redirects the user to Dashboard - does nothing in case of error
        handleLogoClick() {
            const { patientId } = this.$route.params

            if (!patientId) {
                return
            }

            this.$router
                .push({
                    name: 'Dashboard',
                    params: { patientId: this.currentPatientId },
                })
                .catch(() => {
                    // error - NavigationDuplicated
                })
        },

        ...mapActions('patient', ['checkInPatient']),
        ...mapActions('measurement', ['getMeasurement']),
    },
}
</script>
