<template>
    <div class="relative pt-1">
        <div class="flex mb-2 items-center justify-between">
            <div>
                <span
                    class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-primary-600 bg-primary-200 truncate"
                >
                    {{ message }}
                </span>
            </div>
            <div class="text-right">
                <span
                    class="text-xs font-semibold inline-block text-primary-600"
                >
                    {{ progress }}%
                </span>
            </div>
        </div>
        <div
            class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-primary-100"
        >
            <div
                :style="{ width: progress + '%' }"
                class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary-600 transition-all duration-200 ease-linear animate-pulse"
            ></div>
        </div>
    </div>
</template>

<script>
/**
 * @group ATOMS
 * Progress bar for loading visualization
 */
export default {
    inheritAttrs: true,
    props: {
        // The progress as a number
        progress: {
            type: Number,
            required: true,
        },

        // A short message what is currently happing. Should only be some words.
        message: {
            type: String,
            required: true,
        },
    },
}
</script>
