var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-card"},[_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",modifiers:{"reset":true}}],staticClass:"flex flex-wrap  rounded-lg overflow-hidden shadow-md  my-4  border-2  transition-all duration-300 ",class:{
            'bg-gray-200 border-transparent cursor-not-allowed': _vm.disabled,
            'bg-secondary-100 border-primary-100  ': !_vm.disabled,
        }},[_c('div',{staticClass:"card-body h-48 w-full p-4 bg-cover flex",style:({
                'background-image': _vm.getBackgroundImage,
            })},[_c('div',{staticClass:"app-title self-center bg-white w-full rounded-lg px-8 py-4 md:mx-4 text-2xl leading-tight tracking-normal font-bold text-center",class:{
                    'text-gray-500': _vm.disabled,
                    'text-primary-700': !_vm.disabled,
                }},[_vm._t("default")],2)]),_c('div',{staticClass:"card-footer w-full self-end bg-gray-200 shadow-inner p-4"},[_c('div',{staticClass:"flex justify-between"},[_c('div',[(!_vm.disabled)?_c('DropdownButton',{attrs:{"menu":_vm.menu}}):_vm._e()],1),_c('div',[_c('basic-button',{staticClass:"narrow",attrs:{"data-cypress":'selectionCard.newButton.' + _vm.app,"icon":"plus","disabled":_vm.disabled,"to":{
                            name: 'NewMeasurement',
                            params: {
                                patientId: _vm.currentPatientId,
                                appSlug: _vm.app,
                            },
                        }}},[_vm._v("New")])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }