<template>
    <div id="app">
        <div
            v-if="showOfflineMessage"
            class="connection-indicator  bg-red-400 text-red-800 fixed font-bold p-2 rounded m-2 px-10 bottom-0 left-0 w-56 shadow-lg text-center z-500"
        >
            <font-awesome-icon :icon="['fas', 'wifi-slash']" class="mr-2" />No
            internet connection. Your actions might not be saved.

            <a
                class="cursor-pointer hover:font-bold block mt-2"
                @click="showOfflineMessage = !showOfflineMessage"
                >Close</a
            >
        </div>
        <div>
            <transition>
                <router-view :key="$route.path" />
            </transition>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            showOfflineMessage: true,
        }
    },
    /*
          name="fade"
                mode="out-in"
                @beforeLeave="beforeLeave"
                @enter="enter"
                @afterEnter="afterEnter"

                */
    methods: {
        beforeLeave(element) {
            this.prevHeight = getComputedStyle(element).height
        },
        enter(element) {
            const { height } = getComputedStyle(element)

            element.style.height = this.prevHeight

            setTimeout(() => {
                element.style.height = height
            })
        },
        afterEnter(element) {
            element.style.height = 'auto'
        },
    },
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.3s;
    transition-property: height, opacity;
    transition-timing-function: ease;
    overflow: hidden;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}

.connection-indicator {
    display: none;
}

body.offline {
    .connection-indicator {
        display: block;
    }
}
</style>
