<template>
    <div class="mt-5 mb-5">
        <FormulateForm
            v-model="form"
            name="diagnosesForm"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
        >
            <FormulateInput
                type="group"
                name="diagnoses"
                :repeatable="true"
                add-label="+ Add Diagnosis"
                class="max-w-screen-lg mx-auto"
                validation-name="diagnosis"
                validation=""
                error-behavior="live"
            >
                <template v-slot:default>
                    <div class="diagnoses">
                        <div
                            class="grid grid-cols-2 gap-4 mx-auto max-w-screen-lg mb-10 "
                        >
                            <FormulateInput
                                name="code"
                                type="icd"
                                label="ICD-Code and Diagnosis"
                                placeholder="Type to search for ICD-Code"
                                data-cypress="patient.diagnoses.input.icd"
                            />
                            <FormulateInput
                                name="date"
                                label="Initital Diagnosis Date"
                                type="date"
                            />
                        </div>
                    </div>
                </template>
            </FormulateInput>
            <div class="flex justify-end max-w-screen-lg mx-auto pr-12">
                <basic-button
                    class="mr-5"
                    color="gray"
                    data-cypress="patient.diagnoses.back"
                    @click="back"
                >
                    Back
                </basic-button>

                <basic-button
                    :loading="patientLoading"
                    type="submit"
                    icon="check"
                    :disabled="validation.hasErrors"
                    data-cypress="patient.diagnoses.submit"
                >
                    {{ saveText }}
                </basic-button>
            </div>
        </FormulateForm>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import patientDataForm from '@/utils/mixins/patientDataForm'
import { toJson } from 'helpers/common'

/**
 * @group MOLECULES
 * Form to add diagnoses for a patient.
 */

export default {
    // The mixin abstracts the data handling and actions for the form.
    mixins: [patientDataForm],
    inheritAttrs: true,
    data() {
        return {
            form: { diagnoses: [] },
            validation: {},
            dosagePerDay: [],
        }
    },
    computed: {
        ...mapState('patient', {
            currentPatient: state => state.currentPatient,
        }),
    },
    watch: {
        'currentPatient.diagnoses': {
            handler: function(val = [], oldVal = []) {
                if (val.length !== oldVal.length) {
                    this.form.diagnoses = [...this.currentPatient.diagnoses]
                }
            },
            immediate: true,
        },
    },
    methods: {
        submit() {
            this.addPatientData(toJson(this.form)).then(() => {
                this.save()
            })
        },

        ...mapActions('patient', ['addPatientData']),
    },
}
</script>
