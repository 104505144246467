/*
|-------------------------------------------------------------------------------
| VUEX MODULE
|-------------------------------------------------------------------------------
| Authentication Events
*/
import router from '@/router'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import { fb, db, firebase } from '@/services/firebase'
import _ from 'lodash'
import { emptyUpload, emptyMeasurement } from 'db/init'
import { listAllActionsOfStore, listAllGettersOfStore } from 'helpers/common'
import uiStore from 'store/ui'
import { status } from 'helpers/constants'

const mutations = {
    SET_UUID: 'SET_UUID',
    SET_CLOUD_TASK: 'SET_CLOUD_TASK',
    INIT_MEASUREMENT: 'INIT_MEASUREMENT',

    SET_MEASUREMENT: 'SET_MEASUREMENT',
    CLEAR_ACTIVE_MEASUREMENT: 'CLEAR_ACTIVE_MEASUREMENT',
    DELETE_MEASUREMENT: 'DELETE_MEASUREMENT',
    SET_IN_DB: 'SET_IN_DB',

    INIT_UPLOAD: 'INIT_UPLOAD',
    UPDATE_UPLOAD_PROGRESS: 'UPDATE_UPLOAD_PROGRESS',
    SET_FILE_URL: 'SET_FILE_URL',
    SET_UPLOAD_SUCCESSFUL: 'SET_UPLOAD_SUCCESSFUL',
    SET_APP: 'SET_APP',
    START_LISTENER: 'START_LISTENER',
    DESTROY_LISTENER: 'DESTROY_LISTENER',

    SET_STEP: 'SET_STEP',
    SET_MEASUREMENT_LOADING: 'SET_MEASUREMENT_LOADING',
}

export const measurement = {
    namespaced: true,

    state: {
        active: {},
        upload: {},
        cloudTask: null,
        listener: null,
        savedInDatebase: null,
        loading: 0,
    },

    mutations: {
        [mutations.SET_MEASUREMENT_LOADING](state, loadingState) {
            state.loading = loadingState
        },
        // INIT MEASUREMENT
        [mutations.INIT_MEASUREMENT](state, measurement) {
            state.active = measurement
        },

        // Only for debugging
        [mutations.SET_IN_DB](state, boolean) {
            state.savedInDatebase = boolean
        },

        [mutations.SET_MEASUREMENT](state, data) {
            /// See: https://vuejs.org/v2/guide/reactivity.html
            state.active = { ...state.active, ...data }
        },

        [mutations.SET_STEP](state, step) {
            state.active.currentStep = step
        },

        // DELETE UUID
        [mutations.DELETE_MEASUREMENT](state) {
            state.active = {}
        },

        // UPLOAD
        [mutations.INIT_UPLOAD](state, payload) {
            state.upload = payload
        },

        // SET UPLOAD PROGRESS OBJECT
        [mutations.UPDATE_UPLOAD_PROGRESS](state, payload) {
            state.upload[payload.iontype] = payload.uploadProgress
        },

        // SET THE DOWNLOAD URL IN THE ACTIVE MEASUREMENT OBJECT
        [mutations.SET_FILE_URL](state, payload) {
            state.active.files[payload.iontype] = payload.url
        },

        // SET UPLOAD RESULT
        [mutations.SET_UPLOAD_SUCCESSFUL](state, iontype) {
            state.upload[iontype].completed = true
        },

        // Store the lister
        [mutations.START_LISTENER](state, listener) {
            state.listener = listener
        },

        // Destroy the lister
        [mutations.DESTROY_LISTENER](state) {
            state.listener = null
        },
        // Destroy the lister
        [mutations.CLEAR_ACTIVE_MEASUREMENT](state) {
            state.active = {}
        },
    },

    actions: {
        setApp({ commit, dispatch }, appSlug) {
            // Must be VALPROATE
            if (appSlug === 'valproate') {
                commit(mutations.SET_APP, appSlug)
            }
        },

        clearActive({ commit }) {
            commit(mutations.CLEAR_ACTIVE_MEASUREMENT)
            commit(mutations.INIT_UPLOAD)
        },

        /****************************
         * Increase Step Counter
         *
         * @return VOID
         *
         * **********************************************/
        increaseStep({ commit, state }, payload) {
            let currentStep = state.active.currentStep

            if (payload) {
                currentStep = payload
            } else {
                currentStep = currentStep + 1
            }

            commit(mutations.SET_STEP, currentStep)
        },

        /****************************
         * Decrease Step Counter
         *
         * @return VOID
         *
         * **********************************************/
        decreaseStep({ commit, state }, payload) {
            let currentStep = state.active.currentStep

            if (payload) {
                currentStep = payload
            } else {
                currentStep = currentStep - 1
            }

            if (currentStep < 1) {
                currentStep = 1
            }

            commit(mutations.SET_STEP, currentStep)
        },

        // Create new Measurement UUID
        new({ commit, dispatch }) {
            dispatch(uiStore.actionTypes.setLoading, 1, { root: true }) // -> global
            const uuid = uuidv4()
            const upload = emptyUpload()
            const measurement = emptyMeasurement()
            //   SET CREATION DATE
            measurement.createdAt = firebase.firestore.Timestamp.fromDate(
                new Date()
            )

            // SET UUID

            measurement.uuid = uuid

            // SET DATA
            commit(mutations.INIT_MEASUREMENT, measurement)

            // SET DATA
            commit(mutations.INIT_UPLOAD, upload)

            dispatch(uiStore.actionTypes.setLoading, 0, { root: true }) // -> global
        },

        // Save new Measurement in DB
        async save({ commit, state, dispatch, rootGetters }, payload) {
            dispatch(uiStore.actionTypes.setLoading, 1, { root: true }) // -> global

            let measurement = payload || state.active
            const patientId = rootGetters['patient/currentPatientId']

            if (!patientId) {
                throw 'PATIENT ID NOT FOUND - COULD NOT SAVE ENTRY'
            }

            if (!measurement) {
                throw 'MEASUREMENT NOT FOUND - COULD NOT SAVE ENTRY'
            }

            // Create LastUpdatedFlag
            measurement.lastUpdated = firebase.firestore.Timestamp.fromDate(
                new Date()
            )
            let loadingState = 2
            // Save to Firestore
            // Create a new Entry in the Database
            try {
                db.collection('patients')
                    .doc(patientId)
                    .collection('measurements')
                    .doc(measurement.uuid)
                    .set(measurement)

                commit(mutations.SET_IN_DB, true)
            } catch (err) {
                loadingState = 3
            }

            dispatch(uiStore.actionTypes.setLoading, loadingState, {
                root: true,
            })
        },

        /****************************
         * Add Measurement Data
         *
         * @description: Adds new patient info
         *
         * @return Void
         * **********************************************/
        addData({ commit, state }, payload) {
            commit(mutations.SET_MEASUREMENT_LOADING, 1)

            let measurement = state.active

            // ADD updatedAt
            measurement.updatedAt = firebase.firestore.Timestamp.fromDate(
                new Date()
            )

            // Sanitize Payload
            if ('uuid' in payload) {
                delete payload.uuid
            }

            measurement = Object.assign({}, measurement, payload)

            // Commit
            commit(mutations.SET_MEASUREMENT, measurement)
            commit(mutations.SET_MEASUREMENT_LOADING, 2)
        },

        /****************************
         * getMeasurement
         *
         * @description: finds a measurement in DB and loads it into store
         *
         * **********************************************/

        async getMeasurement({ commit, state }, payload) {
            const uuid =
                payload ||
                state.active.uuid ||
                router.currentRoute.params.uuid ||
                null

            if (!uuid) {
                throw new Error('getMeasurement: No UUID provided.')
            }

            const query = db
                .collectionGroup('measurements')
                .where('uuid', '==', uuid)
                .where('deleted', '==', false)
                .limit(1)

            try {
                const querySnapshot = await query.get()
                if (querySnapshot.empty) {
                    throw new Error(
                        'getMeasurement: No Measurement with this UUID found.'
                    )
                }
                // Is always one document due to limit()
                commit(mutations.SET_MEASUREMENT, querySnapshot.docs[0].data())
            } catch (err) {
                throw new Error('getMeasurement: error getting query.', err)
            }
        },

        /****************************
         * Upload measurement files to cloud
         *
         * @description: fWe have two ion types: pos and neg
         *
         * **********************************************/

        uploadFile({ commit, state, dispatch }, payload) {
            const validIonTypes = ['pos', 'neg']
            // Check Ion Type first
            if (!payload.iontype && !validIonTypes.includes(payload.iontype)) {
                throw new Error('ION TYPE IS MALICIOUS. UPLOAD DENIED.')
                //state.upload[iontype]
            }

            // Prepare Task-Object, Filename and Folder according to RFC-Contract
            let uploadTask = {}
            const { iontype, file } = payload
            const { uuid } = state.active
            const filename = `${iontype}.raw`

            // Create the file metadata
            let metadata = {
                orginalFilename: file.name,
            }
            // Upload File to Firebase
            uploadTask = firebase
                .storage()
                .ref(`measurements/${uuid}/${filename}`)
                .put(file, metadata)

            // Now observe the progress by registering a listener
            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on(
                'state_changed', // Firebase Storage State Change
                ({ bytesTransferred, totalBytes, state }) => {
                    const uploadProgress = {
                        error: false,
                        completed: false,
                        status: state,
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded

                        progress: Math.round(
                            (bytesTransferred / totalBytes) * 100
                        ),
                    }
                    const payload = {
                        iontype: iontype,
                        uploadProgress,
                    }

                    // Update the Store
                    commit(mutations.UPDATE_UPLOAD_PROGRESS, payload)
                },
                error => {
                    // ERROR
                    const payload = {
                        iontype: iontype,
                        uploadProgress: {
                            error: true,
                            status: error.code,
                            completed: true,
                            progress: 0,
                        },
                    }

                    commit(mutations.UPDATE_UPLOAD_PROGRESS, payload)
                },
                () => {
                    // SUCCESS
                    // Get the URL and save it to the active document
                    uploadTask.snapshot.ref.getDownloadURL().then(url => {
                        commit(mutations.SET_FILE_URL, {
                            iontype: iontype,
                            url,
                        })
                        // Save Measurement
                        dispatch('save')
                    })
                    // Update the Store
                    commit(mutations.SET_UPLOAD_SUCCESSFUL, iontype)
                }
            )
        },

        /****************************
         * resetUpload
         *
         * @description: Resets the local upload in case of errors
         * @return VOID
         *
         * **********************************************/
        resetUpload({ commit }) {
            let payload = emptyUpload()

            commit(mutations.INIT_UPLOAD, payload)
        },

        /****************************
         * addCloudTask
         *
         * @description: Triggers a Cloud Task to analyse the uploaded files
         * @return PROMISE
         *
         * **********************************************/
        addCloudTask({ commit, state, dispatch }) {
            return new Promise((resolve, reject) => {
                // SET UI TO LOADING
                dispatch(uiStore.actionTypes.setLoading, 1, { root: true }) // -> global

                /*
                 *      CREATE CLOUD TASK API CALL
                 *     ============================
                 *    1. GET a Firebase ID-Token
                 *    2. Make axios GET CALL
                 */

                fb.auth()
                    .currentUser.getIdToken(/* forceRefresh */ true)
                    .then(idToken => {
                        // Token is ready, now create the API call
                        const endpoint = process.env.VUE_APP_ADD_TASK_ENDPOINT
                        const authConfig = {
                            headers: { Authorization: `Bearer ${idToken}` },
                        }
                        let measurement = state.active

                        // Send Call
                        axios
                            .get(endpoint + state.active.uuid, authConfig)
                            .then(response => {
                                // Update the Store with Cloud Task Info
                                measurement.cloudTask = {
                                    status: response.data
                                        ? response.data
                                        : null,
                                    data: response.status
                                        ? response.status
                                        : null,
                                    timestamp: Date.now(),
                                }

                                // update status
                                measurement.status.error = false
                                measurement.status.code = 204
                                measurement.status.progress = 1
                                measurement.status.message =
                                    'Starting Cloud Anaylzer...'
                                measurement.startedCloudTaskAt = firebase.firestore.Timestamp.fromDate(
                                    new Date()
                                )

                                dispatch('save', measurement) // saves it to DB
                                // SET UI TO DONE
                                dispatch(uiStore.actionTypes.setLoading, 2, {
                                    root: true,
                                }) // -> global
                                resolve()
                                // @TODO: set attribute value so that its clear that a cloud task was triggered
                            })
                            .catch(error => {
                                // update status
                                measurement.status.code = error.status
                                measurement.status.error = true
                                measurement.status.message =
                                    'Error while starting Cloud Analyzer'
                                // save it to DB
                                dispatch('save', measurement)
                                // SET UI TO LOADING
                                dispatch(uiStore.actionTypes.setLoading, 3, {
                                    root: true,
                                }) // -> global
                                reject(error)
                            })
                    })
                    .catch(error => {
                        // Error while trying to get a User Token
                        // SET UI TO ERROR
                        dispatch(uiStore.actionTypes.setLoading, 3, {
                            root: true,
                        })
                        // Can accept an Object of options
                        reject('Error while setting a user token.')
                    })
            })
            // Mutate and set
            //commit(mutations.SET_PROCESS, process)
        },

        /****************************
         * getMeasurementsForPatientId
         *
         * @description: Loads 10 documents for a given patient Id
         * @param Patient
         * @return PROMISE - then array of docs
         *
         * **********************************************/
        getMeasurementsForPatientId({ commit, state, dispatch }, payload) {
            return new Promise((resolve, reject) => {
                const patientId =
                    payload || router.currentRoute.params.patientId || null

                if (!patientId) throw 'No Patient Id Given'

                let measurements = []

                // retrieve a collection
                db.collection('patients')
                    .doc(patientId)
                    .collection('measurements')
                    .where('deleted', '==', false)
                    .orderBy('createdAt', 'desc')
                    .limit(10)
                    .get()
                    .then(querySnapshot => {
                        // Take the docs from server reply
                        const documents = querySnapshot.docs.map(doc =>
                            doc.data()
                        )

                        // Build the Array
                        _.forEach(documents, (measurement, key) => {
                            measurements.push(measurement)
                        })

                        // Return Array of Docs
                        resolve(measurements)
                    })
                    .catch(error => {
                        // Return Error
                        reject(error)
                    })
            })
        },

        /****************************
         * startMeasurementsListener
         *
         * @description: Loads 10 documents for a given patient Id
         * @param Patient
         * @return PROMISE - then array of docs
         *
         * **********************************************/
        startMeasurementsListener({ commit, state, dispatch }, payload) {
            return new Promise((resolve, reject) => {
                const patientId =
                    payload || router.currentRoute.params.patientId || null

                if (!patientId) throw 'No Patient Id Given'

                let measurements = []

                // retrieve a collection
                var listener = db
                    .collection('patients')
                    .doc(patientId)
                    .collection('measurements')
                    .where('deleted', '==', false)
                    .orderBy('createdAt', 'desc')
                    .limit(10)
                    .onSnapshot(
                        function(querySnapshot) {
                            var documents = []
                            querySnapshot.forEach(function(doc) {
                                documents.push(doc.data())
                            })

                            // Dispatch Action
                            dispatch('patient/setMeasurements', documents, {
                                root: true,
                            })
                            resolve(listener)
                        },
                        function(err) {
                            // Error
                            reject(err)
                        }
                    )

                return listener
            })
        },

        /****************************
         * subscribe
         *
         * @description: Creates a document subscriber for the current measurement
         *                and save it in the store
         * @return Void
         *
         * **********************************************/

        subscribe({ commit, state, rootGetters }) {
            const patientId =
                rootGetters['patient/currentPatientId'] ||
                router.currentRoute.params.patientId ||
                null
            const uuid =
                state.active.uuid || router.currentRoute.params.uuid || null

            if (!patientId || !uuid) {
                throw 'Error getting patient ID'
            }

            let listener = fb
                .firestore()
                .collection('patients')
                .doc(patientId)
                .collection('measurements')
                .doc(uuid)
                .onSnapshot(
                    snapshot => {
                        /*var source = snapshot.metadata.hasPendingWrites
                        ? 'Local'
                        : 'Server'*/
                        commit(mutations.SET_MEASUREMENT, snapshot.data())
                    },
                    error => {
                        // ERROR
                        //console.error('could not set listener')
                    }
                )

            commit(mutations.START_LISTENER, listener)
        },

        /****************************
         * unsubscribe
         *
         * @description: Deletes the subscribe
         * @return VOID
         *
         * **********************************************/
        unsubscribe({ commit, state, rootGetters }) {
            // Stop listener by calling it again
            const listener = state.listener

            if (listener) {
                listener()
            }

            commit(mutations.DESTROY_LISTENER)
        },

        /****************************
         * Delete
         *
         * @description: Deletes the subscribe
         * @param UUID of Measurement
         * @return PROMISE
         *
         * **********************************************/
        delete({ commit, state, dispatch }, uuid) {
            return new Promise((resolve, reject) => {
                if (!uuid) {
                    reject('No UUID provided.')
                }

                dispatch(uiStore.actionTypes.setLoading, 1, { root: true }) // -> global

                let measurement = {}

                const query = db
                    .collectionGroup('measurements')
                    .where('uuid', '==', uuid)
                    .limit(1)

                /* query
                    .get()
                    .then(querySnapshot => {
                        if (querySnapshot.empty) {
                            reject('No Measurement with this UUID found.')
                        }

                        querySnapshot.forEach(function(doc) {
                            doc.ref
                                .delete()
                                .then(() => {
                                    dispatch(uiStore.actionTypes.setLoading, 2, { root: true }) // -> global
                                    commit(mutations.DELETE_MEASUREMENT)
                                    resolve('Deleted Measurement')
                                })
                                .catch(err => {
                                    // Return Error
                                    console.log(err)
                                    dispatch(uiStore.actionTypes.setLoading, 3, { root: true }) // -> global
                                    reject(err)
                                })
                        })
                    })*/

                query
                    .get()
                    .then(querySnapshot => {
                        if (querySnapshot.empty) {
                            reject('No Measurement with this UUID found.')
                        }

                        querySnapshot.forEach(doc => {
                            // Is always one document due to limit()
                            measurement = doc.data()
                            measurement.deleted = true

                            // Upate Patient's Measurement List
                            dispatch('patient/getMeasurements', null, {
                                root: true,
                            })

                            dispatch('save', measurement)
                            commit(mutations.DELETE_MEASUREMENT)
                            dispatch(uiStore.actionTypes.setLoading, 2, {
                                root: true,
                            }) // -> global
                            resolve()
                        })
                    })
                    .catch(() => {
                        // Error with Query
                        dispatch(uiStore.actionTypes.setLoading, 3, {
                            root: true,
                        }) // -> global
                        reject('Could not query Database...')
                    })
            })
        },
    },

    getters: {
        isLoading: state => state.loading === 1,
        isComplete: state => _.get(state, 'active.completed', null),
        getUUID: state => _.get(state, 'active.uuid', null),
        getCurrentStep: state => _.get(state, 'active.currentStep', 1),
        hasCloudTask: state => (_.size(state.active.cloudTask) ? true : false),
        hasActiveMeasurement: state => (_.size(state.active) ? true : false),
        hasPosFile: state => _.get(state, 'active.files.pos', null),
        hasNegFile: state => _.get(state, 'active.files.neg', null),
        hasFiles: (state, getters) => getters.hasPosFile && getters.hasNegFile,
        hasLocalFiles: state => {
            const completed = {
                neg: _.get(state, 'upload.pos.completed', null),
                pos: _.get(state, 'upload.pos.completed', null),
            }
            return completed.neg && completed.neg
        },
        hasResults: state =>
            _.get(state, 'active.results.free', null) &&
            _.get(state, 'active.results.total', null),

        hasError: state => _.get(state, 'active.status.error', null),
        hasErrorCode: state => _.get(state, 'active.status.code', null),
        hasProgress: state => _.get(state, 'active.status.progress', null),
        // @TODO Implement Results interpretation
        resultsInterpretation: state => true,
        getStatus: (state, getters) => {
            const {
                active: { completed },
            } = state
            const { hasFiles, hasError } = getters

            let finalStatus = status.incomplete
            if (!completed && hasFiles) {
                finalStatus = status.waiting
            } else if (completed && hasFiles && !hasError) {
                finalStatus = status.complete
            } else if (completed && hasFiles && hasError) {
                finalStatus = status.fail
            }
            return finalStatus
        },
        runtime: ({ active }) => {
            let runtime = 0
            if (active.completed) {
                const start = active.startedCloudTaskAt.seconds
                const finished = active.completedAt.seconds
                runtime = finished - start
            }
            return runtime
        },
    },
}

listAllActionsOfStore(measurement, 'measurement')
listAllGettersOfStore(measurement, 'measurement')

export default measurement
