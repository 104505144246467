<template>
    <form class="" @submit.prevent="submit">
        <basic-alert
            v-if="error.message"
            :key="error.timestamp"
            bold-text="Sign in failed."
            type="error"
            class="mt-5"
            data-cypress="login.alert"
            >{{ error.message }}</basic-alert
        >
        <div class="my-5">
            <basic-input
                v-model="form.email"
                icon="envelope"
                type="email"
                name="email"
                label="Email"
                data-cypress="login.email"
                placeholder="Email Address"
            ></basic-input>
        </div>
        <div class="my-5">
            <basic-input
                v-model="form.password"
                icon="lock"
                type="password"
                name="password"
                label="Password"
                data-cypress="login.password"
            ></basic-input>
        </div>
        <div class="my-5 flex justify-end">
            <basic-button
                type="submit"
                :loading="loading"
                data-cypress="login.submit"
                >Sign in</basic-button
            >
        </div>
    </form>
</template>

<script>
// eslint-disable-next-line no-unused-vars
/**
 * @group MOLECULES
 * ...
 */
import { mapActions, mapGetters } from 'vuex'
export default {
    data() {
        return {
            form: {
                email: '',
                password: '',
            },
        }
    },
    computed: {
        ...mapGetters({
            loading: 'auth/loading',
            error: 'auth/error',
        }),
    },

    mounted() {
        this.init()
    },
    methods: {
        submit() {
            this.login(this.form)
        },
        ...mapActions({
            init: 'auth/init',
            login: 'auth/login',
        }),
    },
}
</script>
