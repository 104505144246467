<template>
    <div v-scroll-reveal class="w-full sm:w-1/2 md:w-1/4 xl:w-1/5 pr-10">
        <template v-if="!measurement">
            <div
                class="flex flex-wrap w-full sm:w-56 md:w-96 rounded-lg overflow-hidden my-4 bg-gray-200"
            >
                <div class="card-body h-48 w-full p-4 bg-contain flex empty">
                    <EmptyIcon
                        alt="No data yet"
                        class="w-12 mx-auto h-auto fill-current text-gray-400"
                    />
                </div>

                <div class="card-footer w-full self-end bg-gray-100  p-4">
                    <div class="text-center">
                        <span class="font-bold text-gray-500 text-sm"
                            >No measurements available</span
                        >
                    </div>
                </div>
            </div>
        </template>

        <template v-else>
            <div
                class="flex flex-wrap w-full rounded-lg overflow-hidden my-4 bg-gray-200 hover:shadow-lg duration-300 transition-all cursor-pointer"
                @click.prevent="showResults(measurement)"
            >
                <div class="card-body h-40 w-full p-4 bg-contain empty">
                    <div class="flex flex-row justify-between">
                        <MeasurementStatus
                            :status="measurement.status"
                            class="mt-1"
                        />
                        <basic-label v-if="measurement.completed" color="gray"
                            >Completed</basic-label
                        >
                        <basic-label v-else color="orange">Running</basic-label>
                    </div>

                    <div class="flex flex-col justify-between mt-10">
                        <p class="text-xs text-gray-700 mb-2 capitalize">
                            <font-awesome-icon
                                :icon="['fas', 'vials']"
                                class="mr-2"
                            />
                            {{ measurement.app }}
                        </p>

                        <p class="text-xs text-gray-700 mb-2">
                            <font-awesome-icon
                                :icon="['fas', 'calendar']"
                                class="mr-2"
                            />
                            {{ measurement.createdAt.seconds | date }}
                        </p>
                        <p class="text-xs text-gray-700 mb-3">
                            <font-awesome-icon
                                :icon="['fas', 'clock']"
                                class="mr-2"
                            />
                            {{ measurement.createdAt.seconds | time }}
                        </p>
                    </div>
                </div>

                <div class="card-footer w-full self-end bg-gray-100 p-4">
                    <div class="flex justify-between px-5">
                        <div>
                            <p class="text-lg font-bold text-gray-700">
                                {{ measurement.results.free | round }}
                            </p>
                            <p
                                class="uppercase text-xs text-gray-500 tracking-wider"
                            >
                                Free
                            </p>
                        </div>
                        <div>
                            <p class="text-lg font-bold text-gray-700">
                                {{ measurement.results.total | round }}
                            </p>
                            <p
                                class="uppercase text-xs text-gray-500 tracking-wider"
                            >
                                Total
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import EmptyIcon from '@/assets/img/empty.svg'

/**
 * @group MOLECULES
 * A measurement card is a clickable placeholder to display measurements
 */
export default {
    components: {
        EmptyIcon,
    },
    inheritAttrs: true,
    props: {
        // Is the measurement Empty?
        empty: {
            type: Boolean,
            default: false,
        },

        // Was there an error?
        error: {
            type: Boolean,
            default: false,
        },

        // The Measurement Object
        measurement: {
            type: Object,
            default: null,
        },
    },

    methods: {
        // @vuese
        // Click handler for measurement. Pushes the route to `ShowResults`
        // @arg measurement `object`
        showResults(measurement) {
            this.$router.push({
                name: 'ShowResults',
                params: { uuid: measurement.uuid, appSlug: measurement.app },
            })
        },
    },
}
</script>
