<template>
    <div class="skeleton-loader">
        <div class="flex justify-between">
            <h2 class="text-xl  text-gray-200 animate-pulse">
                Concentration of Valproic Acid (VPA)
            </h2>
            <h6 class="text-xs lg:text-xl text-gray-100 animate-puls">
                Date
            </h6>
        </div>
        <div class="flex justify-between flex-row flex-wrap">
            <div
                class="value-box w-full lg:w-1/4 rounded-lg  bg-gray-200 p-4 flex-shrink-0 animate-pulse h-64 "
            >
                <div
                    class="w-3/4 bg-gray-300 animate-pulse h-16 mx-auto mb-4"
                ></div>
                <div class="w-3/4 bg-gray-300 animate-pulse h-16 mx-auto"></div>
            </div>

            <div class="w-full lg:w-3/4">
                <div
                    class="value-box  rounded-lg  bg-gray-200 p-4 flex-shrink-0 animate-pulse h-24 mx-4 mb-5"
                >
                    <div
                        class="w-3/4 bg-gray-300 animate-pulse h-16  mx-auto"
                    ></div>
                </div>

                <div
                    class="value-box  rounded-lg  bg-gray-200 p-4 flex-shrink-0 animate-pulse  mx-4"
                >
                    <div
                        class="w-3/4 bg-gray-300 animate-pulse h-24  mx-auto"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * @group MOLECULES
 * A nice Skeleton loader with a CSS animation to indicate loading.
 */
export default {
    inheritAttrs: true,
}
</script>
