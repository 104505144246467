var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FormulateForm',{staticClass:"checkin-form",on:{"validation":function($event){_vm.validation = $event},"submit":_vm.submit},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('FormulateInput',{attrs:{"tabindex":"1","type":"autocomplete","label":"Patient ID","autocomplete":"no","name":"patientId","options":_vm.allPatients,"placeholder":"Search for Patient ID","bail":"","allow-1password":"no","validation":"bail|required|max:10|min:10|validPatientId|inDataset","validation-messages":{
                validPatientId: 'This ID is not a valid DBI PatientID',
                inDataset: 'The ID was not found in our Database',
            },"validation-rules":{
                inDataset: function (ref) {
                            var value = ref.value;

                            return _vm.allPatientIds.includes(value);
},
            },"data-cypress":"patient.checkIn.input"},on:{"enter":function($event){return _vm.submit()}},model:{value:(_vm.form.patientId),callback:function ($$v) {_vm.$set(_vm.form, "patientId", $$v)},expression:"form.patientId"}}),(!_vm.validation.hasErrors)?_c('div',{staticClass:"text-green-600 font-bold"},[_vm._v(" Selected Patient: "+_vm._s(_vm.form.patientId)+" ")]):_vm._e(),_c('div',{staticClass:"flex justify-end"},[_c('FormulateInput',{attrs:{"type":"submit","tabindex":"2","label":"Check-in","disabled":_vm.validation.hasErrors,"data-cypress":"patient.checkIn.submit"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }