import Vue from 'vue'
import VueRouter from 'vue-router'
import VueRouteMiddleware from 'vue-route-middleware'

/**  Middleware */
import authenticated from 'router/middleware/authenticated'
import patient from 'router/middleware/patient'
import measurement from 'router/middleware/measurement'

/** CORE VIEW COMPONENT */
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'
import Create from '../views/measurement/Create.vue'
import Upload from '../views/measurement/Upload.vue'
import PerformMeasurement from '../views/measurement/PerformMeasurement.vue'

Vue.use(VueRouter)

const appStartRoute = 'PatientCheckIn'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        // Durin Dev
        redirect: '/patientCheckIn',
    },
    {
        path: '/about',
        name: 'About',
        /* webpackChunkName: "about" */
        component: () => import('../views/About.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        /* webpackChunkName: "auth" */
        component: () => import('@/views/auth/Login.vue'),
    },
    {
        path: '/logout',
        name: 'Logout',
        /* webpackChunkName: "auth" */
        component: () => import('@/views/auth/Logout.vue'),
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () => import('../views/app/Settings.vue'),
        meta: {
            middleware: [authenticated],
        },
    },
    {
        path: '/export/:appSlug?',
        name: 'Export',
        component: () => import('../views/app/Export.vue'),
        meta: {
            middleware: [authenticated],
        },
    },
    {
        path: '/PatientCheckIn',
        name: 'PatientCheckIn',
        component: () => import('../views/patient/CheckIn.vue'),
        meta: {
            middleware: [authenticated],
        },
    },
    {
        path: '/patient/:patientId',
        name: 'Dashboard',
        component: () => import('../views/Dashboard.vue'),
        meta: {
            middleware: [authenticated, patient],
        },
    },
    {
        path: '/patient/:patientId/medication',
        name: 'Medication',
        component: () => import('../views/patient/Medication.vue'),
        meta: {
            middleware: [authenticated, patient],
        },
    },
    {
        path: '/patient/:patientId/diagnoses',
        name: 'Diagnoses',
        component: () => import('../views/patient/Diagnoses.vue'),
        meta: {
            middleware: [authenticated, patient],
        },
    },
    {
        path: '/patient/:patientId/demographics',
        name: 'Demographics',
        component: () => import('../views/patient/Demographics.vue'),
        meta: {
            requiresAuth: true,
            middleware: [authenticated, patient],
        },
    },
    {
        path: '/patient/checkout',
        name: 'Checkout',
        component: () => import('../views/patient/CheckOut.vue'),
        meta: {
            middleware: [authenticated],
        },
    },
    // Agree to Data Usage and then New Measurement
    {
        path: '/patient/:patientId/:appSlug/new',
        name: 'NewMeasurement',
        component: () => import('../views/measurement/DataUsageAgreement.vue'),
        meta: {
            middleware: [authenticated],
        },
    },
    //  Collect Patient Data
    {
        path: '/patient/:patientId/:appSlug/:uuid/setup',
        name: 'PatientDataCollection',
        component: () =>
            import('../views/measurement/PatientDataCollection.vue'),
        meta: {
            middleware: [authenticated],
        },
    },
    // Create Measurement in the Database
    {
        path: '/patient/:patientId/:appSlug/:uuid/create',
        name: 'CreateMeasurement',
        component: Create,
        meta: {
            middleware: [authenticated],
        },
    },
    //  Show Explantion Video
    {
        path: '/patient/:patientId/:appSlug/:uuid/explanation',
        name: 'Explanation',
        component: () => import('../views/measurement/Explanation.vue'),
        meta: {
            middleware: [authenticated],
        },
    },
    //  Tell user to perform measurement
    {
        path: '/patient/:patientId/:appSlug/:uuid/measure',
        name: 'PerformMeasurement',
        component: PerformMeasurement,
        meta: {
            middleware: [authenticated],
        },
    },
    // Upload files of measurement
    {
        path: '/patient/:patientId/:appSlug/:uuid/upload',
        name: 'UploadMeasurement',
        component: Upload,
        meta: {
            middleware: [authenticated, measurement],
        },
    },
    // Show Measurement Result
    {
        path: '/patient/:patientId/:appSlug/:uuid/results',
        name: 'ShowResults',
        component: () => import('../views/measurement/Results.vue'),
        meta: {
            middleware: [authenticated],
        },
    },
    /** 404 - Not Found **/
    {
        // will match everything
        path: '*',
        name: 'NotFound',
        component: NotFound,
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
})

router.beforeEach(VueRouteMiddleware())

export { router, appStartRoute }
export default router
