<template>
    <div class="mt-5 mb-5">
        <FormulateForm
            v-model="patient"
            error-behavio="live"
            @validation="validation = $event"
            @submit="submit()"
        >
            <div class="grid grid-cols-2 gap-4 mx-auto max-w-screen-lg">
                <div>
                    <FormulateInput
                        type="text"
                        name="firstname"
                        label="First Name"
                    />
                </div>

                <div>
                    <FormulateInput
                        type="text"
                        name="lastname"
                        label="Last Name"
                    />
                </div>
                <div>
                    <p class="mb-2 font-bold text-secondary-700">Birthday</p>
                    <p
                        class="font-bold text-secondary-700 bg-gray-100 rounded-lg px-4 py-6 leading-none cursor-not-allowed"
                    >
                        {{ patient.birthday | date }}
                    </p>
                    <!-- 
                    <FormulateInput
                        type="date"
                        label="Date of Birth"
                        placeholder="dd-mm-YYYY"
                        name="birthday"
                        validation="date|before"
                    /> -->
                </div>
                <div>
                    <FormulateInput
                        type="select"
                        :options="{
                            Select: 'Select',
                            Male: 'Male',
                            Female: 'Female',
                            Other: 'Other',
                        }"
                        label="Gender"
                        name="gender"
                    />
                </div>
                <div>
                    <FormulateInput
                        type="number"
                        label="Weight (kg)"
                        placeholder="Weight"
                        name="weight"
                        validation="bail|number|min:0|max:200"
                    />
                </div>
                <div>
                    <FormulateInput
                        type="number"
                        label="Height (cm)"
                        placeholder="Height"
                        name="height"
                        validation="bail|number|min:0|max:240"
                    />
                </div>
                <div>
                    <FormulateInput
                        v-model="bmi"
                        type="text"
                        label="Body Mass Index (BMI)"
                        placeholder="BMI"
                        name="bmi"
                        help="BMI is calculated automatically"
                        disabled
                    />
                </div>
            </div>
            <div class="flex justify-end max-w-screen-lg mx-auto">
                <basic-button
                    class="mr-5"
                    color="gray"
                    data-cypress="patient.demographics.back"
                    @click="back"
                >
                    Back
                </basic-button>
                <basic-button
                    :loading="patientLoading"
                    type="submit"
                    icon="check"
                    :disabled="validation.hasErrors"
                    data-cypress="patient.demographics.submit"
                >
                    {{ saveText }}
                </basic-button>
            </div>
        </FormulateForm>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import patientDataForm from '@/utils/mixins/patientDataForm'

/**
 * @group MOLECULES
 * The Form to gather demographic data for the patient.
 */
export default {
    // The mixin abstracts the data handling and actions for the form.
    mixins: [patientDataForm],
    inheritAttrs: true,
    data() {
        return {
            patientForm: {},
            validation: {},
        }
    },
    computed: {
        patient: {
            get() {
                return this.currentPatient
            },
            set(value) {
                // Add to VUEX State
                this.addPatientData(value)
            },
        },
        // Calculcates the BMI with Getter & Setter
        bmi: {
            // getter
            get: function() {
                const weight = parseInt(
                    this.patient['weight'] ? this.patient.weight : 0
                )
                const height = parseInt(
                    this.patient['height'] ? this.patient.height : 0
                )

                let bmi = 0

                if (weight > 0 && height > 0) {
                    bmi = Math.round(weight / (height / 100) ** 2)
                }
                return bmi
            },
            // setter
            set: function(newValue) {
                this.patient.bmi = newValue
            },
        },
        ...mapState('patient', {
            currentPatient: state => state.currentPatient,
        }),
        ...mapGetters('patient', ['patientLoading']),
    },

    methods: {
        submit() {
            this.save()
        },
        ...mapActions('patient', ['addPatientData', 'savePatient']),
    },
}
</script>
