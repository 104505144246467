/*
|-------------------------------------------------------------------------------
| VUEX MODULE
|-------------------------------------------------------------------------------
| Authentication Events
*/
import router from '@/router'
import { fb, db } from '@/services/firebase'
import _ from 'lodash'
import { listAllActionsOfStore, listAllGettersOfStore } from 'helpers/common'

const mutations = {
    SAVE_SETTINGS: 'SAVE_SETTINGS',
    SET_SETTINGS_LOADING: 'SET_SETTINGS_LOADING',
}

export const settings = {
    namespaced: true,

    state: {
        country: 'CH',
        device: 'not defined',
        // Loading Model: 0,1,2,3,
        loading: 0,
    },

    mutations: {
        // Store an Object in the State based on the config
        [mutations.SAVE_SETTINGS](state, settings) {
            _.each(_.keys(settings), saveKey => {
                state[saveKey] = _.get(settings, saveKey, '')
            })
        },

        // LOADING
        [mutations.SET_SETTINGS_LOADING](state, loading) {
            state.loading = loading
        },
    },

    actions: {
        saveSettings({ commit, rootGetters }, settings) {
            return new Promise((resolve, reject) => {
                commit(mutations.SET_SETTINGS_LOADING, 1)
                const user = rootGetters['user/currentUser']

                if (user) {
                    // Settings are stored based on the UUID of the logged-in User
                    let docRef = db.collection('settings').doc(user.uid)

                    docRef
                        .set(settings)
                        .then(doc => {
                            commit(mutations.SAVE_SETTINGS, settings)
                            commit(mutations.SET_SETTINGS_LOADING, 2)
                            resolve()
                        })
                        .catch(error => {
                            commit(mutations.SET_SETTINGS_LOADING, 3)
                            reject(error)
                        })
                } else {
                    commit(mutations.SET_SETTINGS_LOADING, 3)
                    reject('No User logged in')
                }
            })
        },

        initSettings({ state, commit, rootGetters }) {
            return new Promise((resolve, reject) => {
                commit(mutations.SET_SETTINGS_LOADING, 1)
                const user = rootGetters['user/currentUser']

                if (user) {
                    // Settings are stored based on the UUID of the logged-in User
                    let docRef = db.collection('settings').doc(user.uid)

                    // We will only initalize Country and Device
                    const settings = {}
                    settings.country = state.country
                    settings.device = state.device

                    docRef
                        .set(settings)
                        .then(doc => {
                            commit(mutations.SAVE_SETTINGS, settings)
                            commit(mutations.SET_SETTINGS_LOADING, 2)
                            resolve()
                        })
                        .catch(error => {
                            commit(mutations.SET_SETTINGS_LOADING, 3)
                            reject(error)
                        })
                } else {
                    commit(mutations.SET_SETTINGS_LOADING, 3)
                    reject('No User logged in')
                }
            })
        },

        getSettings({ commit, dispatch, rootGetters }, user) {
            return new Promise((resolve, reject) => {
                commit(mutations.SET_SETTINGS_LOADING, 1)
                const user = user || rootGetters['user/currentUser']

                if (user) {
                    let docRef = db.collection('settings').doc(user.uid)

                    docRef
                        .get()
                        .then(doc => {
                            if (doc.exists) {
                                const settings = doc.data()
                                commit(mutations.SAVE_SETTINGS, settings)
                                commit(mutations.SET_SETTINGS_LOADING, 2)
                                resolve()
                            } else {
                                // No Settings exist, so we initalize it
                                dispatch('initSettings')
                                commit(mutations.SET_SETTINGS_LOADING, 3)
                            }
                        })
                        .catch(error => {
                            commit(mutations.SET_SETTINGS_LOADING, 3)
                            reject(error)
                        })
                } else {
                    commit(mutations.SET_SETTINGS_LOADING, 3)
                    reject('No User logged in')
                }
            })
        },
    },

    getters: {
        isLoading: state => state.loading === 1,
        getCountry: state => state.country || null,
    },
}

listAllActionsOfStore(settings, 'settings')
listAllGettersOfStore(settings, 'settings')

export default settings
