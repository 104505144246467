<template>
    <div class="bg-white h-screen">
        <header></header>
        <!-- Slot for all contents -->
        <slot></slot>
    </div>
</template>

<script>
/**
 * @group TEMPLATES
 *  A template with white background header amd main slots
 *
 */
export default {
    components: {},
    props: {},
}
</script>
