<template>
    <div>
        <h1
            class="text-xl md:text-3xl lg:text-6xl text-left mt-10 ml-10 font-bold text-secondary-300"
        >
            Page not found.
        </h1>
        <router-link
            :to="{ name: 'Dashboard' }"
            class="mt-10 ml-10 text-lg no-underline hover:underline"
            >Go to Start<font-awesome-icon
                :icon="['fas', 'arrow-circle-right']"
                class="ml-2"
        /></router-link>
    </div>
</template>

<script>
export default {}
</script>
