<template>
    <div class="flex justify-center items-center min-h-screen ">
        <div
            class="login-box max-w-md w-full mx-4 bg-secondary-100 p-8 px-12 shadow-lg rounded-lg"
        >
            <LogoUnicolor class="mx-6 mb-8 " />
            <LoginForm />
        </div>
    </div>
</template>

<script>
/**
 * @group ORGANISMS
 * Renders a logo and a LoginForm for the user to authenicate
 */
export default {}
</script>
