// Google Firebase Config

import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
// @TODO: Can be removed later since with do calls with AXIOS
import 'firebase/functions'

// firebase init goes here
const config = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
    functionsRegion: process.env.VUE_APP_FIREBASE_FUNCTIONS_REGION,
}

// Startup up the App Init Process
const fb = firebase.initializeApp(config)

// firebase utils
const auth = firebase.auth()
const db = firebase.firestore()
const currentUser = firebase.auth().currentUser

const functions = firebase.functions()

/****************
 * EXPORT
 ****************/

export default fb

export { config, firebase, fb, db, functions, auth, currentUser }
