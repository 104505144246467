<template>
    <div class="gauge-component flex flex-row pb-6">
        <div class="title w-1/5 flex-shrink-0 ">
            <div class="flex flex-col content-center justify-center h-20">
                <h5 class="text-xl text-left font-bold text-secondary-700">
                    {{ title }}
                </h5>
                <p class="unit text-left text-secondary-700 text-base">
                    {{ unit }}
                    <a v-tooltip.left="info">
                        <font-awesome-icon
                            :icon="['fas', 'info-circle']"
                            class="mx-1"
                    /></a>
                </p>
            </div>
        </div>
        <div class="gauge-holder w-4/5">
            <span
                class="value mb-2 block font-bold text-gray-700"
                :style="{ marginLeft: `${percentage}%` }"
            >
                <span>{{ value | round }}</span>
                <span>{{ unit }}</span></span
            >
            <div
                class="bar rounded-lg  w-full bg-gradient-to-r from-red-400 via-green-500 to-red-400"
            >
                <div
                    class="indicator font-bold border-l-2 border-gray-800 p-3 py-4"
                    :style="{ marginLeft: `${percentage}%` }"
                ></div>
            </div>
            <div class="flex flex-row justify-between text-gray-700">
                <span>{{ min }}</span>
                <span>{{ max }}</span>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * @group MOLECULES
 * Renders a gauge to visualize the measurement result
 */
export default {
    props: {
        // Title of the gauge
        title: {
            type: String,
            required: true,
        },

        // Unit of the result
        unit: {
            type: String,
            required: true,
        },
        // Some info text show in a popover
        info: {
            type: String,
            required: true,
        },

        // The actual value of the measured result
        value: {
            type: Number,
            required: true,
        },

        // Min value of the gauge
        min: {
            type: Number,
            required: true,
        },

        // Max value of the gauge
        max: {
            type: Number,
            required: true,
        },

        // The MIN of the therapeutic range
        therapeuticMin: {
            type: Number,
            required: true,
        },

        // The MAX of the therapeutic range
        therapeuticMax: {
            type: Number,
            required: true,
        },
    },

    computed: {
        percentage() {
            if (this.value > this.min && this.value <= this.max) {
                return (this.value / this.max) * 100
            } else {
                return 0
            }
        },
    },
}
</script>
