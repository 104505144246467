<template>
    <div class="p-5">
        <div
            class="sk-chase"
            :style="{ width: width + 'px', height: width + 'px' }"
        >
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
        </div>
    </div>
</template>

<script>
/**
 * @group ATOMS
 * An alternative loading spinner
 */
export default {
    inheritAttrs: true,
    props: {
        // Width of the spinner in px
        width: {
            type: Number,
            default: 40,
        },
    },
}
</script>

<style lang="scss" scoped>
.sk-chase {
    position: relative;
    animation: sk-chase 2.5s infinite linear both;
}
.sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: sk-chase-dot 2s infinite ease-in-out both;
}
.sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    @apply bg-primary-500;
    border-radius: 100%;
    animation: sk-chase-dot-before 2s infinite ease-in-out both;
}
.sk-chase-dot:nth-child(1) {
    animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
    animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
    animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
    animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
    animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
    animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
    animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
    animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
    animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
    animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
    animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
    animation-delay: -0.6s;
}
@keyframes sk-chase {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes sk-chase-dot {
    80%,
    100% {
        transform: rotate(360deg);
    }
}
@keyframes sk-chase-dot-before {
    50% {
        transform: scale(0.4);
    }
    100%,
    0% {
        transform: scale(1);
    }
}
</style>
