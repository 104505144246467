import store from 'store'
import measurementStore from 'store/measurement'

export default function patient(to, from, next) {
    if (store.getters[measurementStore.getterTypes.hasActiveMeasurement]) {
        return next()
    }

    return next({
        to: 'Dashboard',
    })
}
