<template>
    <div>
        <component :is="tag" class="headline-style">
            <!-- Headline Text -->
            <slot><!-- `null` --></slot>
        </component>
    </div>
</template>

<script>
/**
 * @group ATOMS
 * Renders a simple headline
 */
export default {
    inheritAttrs: true,
    props: {
        // HTML tag of the headline.
        tag: {
            // `'h1'` / `'h2'`/ `'h3'`/ `'h4'`
            type: String,
            default: 'h2',
            validator: function(value) {
                // The value must match one of these strings
                return ['h1', 'h2', 'h3', 'h4'].indexOf(value) !== -1
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.headline-style {
    @apply text-center text-3xl text-primary-500 font-bold mt-5 mb-5;
}
</style>
