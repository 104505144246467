<template>
    <div class="">
        <div
            class="spinner w-4 h-4 mx-auto relative"
            :class="{ width, height }"
        >
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
        </div>
    </div>
</template>

<script>
/**
 * @group ATOMS
 * A loading spinner for nicer loading UIs
 */
export default {
    $inheritAttrs: true,

    props: {
        // Width of the spinner in Tailwind Sizing
        width: {
            type: String,
            default: 'w-4',
        },

        // Height of the spinner in Tailwind Sizing
        height: {
            type: String,
            default: 'h-4',
        },
    },
}
</script>

<style lang="scss" scoped>
.spinner {
}

.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: white;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }
    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}
</style>
