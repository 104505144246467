/*
|-------------------------------------------------------------------------------
| VUEX MODULE
|-------------------------------------------------------------------------------
| Authentication Events
*/
import router from '@/router'
import { calculatePatientId, dateFromString } from '@/services/dbi'
import { db, firebase } from '@/services/firebase'
import _ from 'lodash'
import measurementStore from 'store/measurement'
import { listAllActionsOfStore, listAllGettersOfStore } from 'helpers/common'

const mutations = {
    SET_CURRENT_PATIENT: 'SET_CURRENT_PATIENT',
    SET_ALL_PATIENTS: 'SET_ALL_PATIENTS',
    SET_ALL_PATIENT_IDS: 'SET_ALL_PATIENT_IDS',
    CREATE_PATIENT: 'CREATE_PATIENT',
    ADD_PATIENTS_MEASUREMENTS: 'ADD_PATIENTS_MEASUREMENTS',
    ADD_PATIENT_DATA: 'ADD_PATIENT_DATA',
    SET_PATIENT_LOADING: 'SET_PATIENT_LOADING',
}

export const patient = {
    namespaced: true,

    state: {
        currentPatient: {},
        allPatients: [],
        allPatientIds: [],
        // Loading-States: 0,1,2,3
        loading: 0,
    },

    mutations: {
        [mutations.CREATE_PATIENT](state, patient) {
            state.allPatients.push(patient)
            state.allPatientIds.push(patient.patientId)
        },

        // Stores a user object from firebase in the state store
        [mutations.SET_CURRENT_PATIENT](state, patient) {
            state.currentPatient = patient
        },

        [mutations.SET_ALL_PATIENTS](state, patients) {
            state.allPatients = patients
        },
        [mutations.SET_ALL_PATIENT_IDS](state, patientIds) {
            state.allPatientIds = patientIds
        },
        // Creates a new object key and adds measurements
        [mutations.ADD_PATIENTS_MEASUREMENTS](state, measurements) {
            /// Workaround to tickle Vue's reactiveness
            state.currentPatient.measurements = measurements
            state.currentPatient = { ...state.currentPatient }
        },

        [mutations.ADD_PATIENT_DATA](state, data) {
            /// See: https://vuejs.org/v2/guide/reactivity.html
            state.currentPatient = { ...state.currentPatient, ...data }
        },

        [mutations.SET_PATIENT_LOADING](state, loadingState) {
            state.loading = loadingState
        },
    },
    actions: {
        checkOutPatient({ commit }) {
            commit(mutations.SET_CURRENT_PATIENT, null)
        },

        /*
        * Create new Patient in the DB and check in

        * @Return: Promise
        */
        registerAndCheckIn({ commit, dispatch }, patient) {
            return new Promise((resolve, reject) => {
                patient.patientId = calculatePatientId(patient)

                if (!patient.patientId) {
                    throw new Error('Error while creating patient ID')
                }

                try {
                    // Add createdAt
                    patient.createdAt = firebase.firestore.Timestamp.fromDate(
                        new Date()
                    )
                    patient.updatedAt = patient.createdAt

                    // Parse Birthday
                    let birthdayParsed = dateFromString(patient.birthday)

                    patient.birthday = firebase.firestore.Timestamp.fromDate(
                        birthdayParsed
                    )

                    // Create a new Entry in the Database
                    db.collection('patients')
                        .doc(patient.patientId)
                        .set(patient)
                        .then(() => {
                            commit(mutations.CREATE_PATIENT, patient)
                            // Check in
                            commit(mutations.SET_CURRENT_PATIENT, patient)
                            resolve(patient)
                        })
                        .catch(error => {
                            reject(error)
                        })
                } catch (err) {
                    reject(err)
                }
            })
        },

        checkInPatient({ commit, state, getters }, payload) {
            return new Promise((resolve, reject) => {
                const patientId =
                    payload || router.currentRoute.params.patientId || null

                if (!patientId) {
                    throw 'Error getting patient ID'
                }
                commit(mutations.SET_PATIENT_LOADING, 1)
                var docRef = db.collection('patients').doc(patientId)

                if (!getters.hasActivePatient) {
                    docRef.get().then(patientDoc => {
                        if (patientDoc.exists) {
                            // Commit to State
                            commit(
                                mutations.SET_CURRENT_PATIENT,
                                patientDoc.data()
                            )

                            commit(mutations.SET_PATIENT_LOADING, 2)
                            resolve('Patient was checked in successfully')

                            /*router
                                .replace({
                                    name: 'Dashboard',
                                    params: { patientId: patientId },
                                })
                                .catch(() => {
                                    // Catching the error message in case we set the param already
                                })*/
                        } else {
                            // doc.data() will be undefined in this case

                            reject('Patient to check-in not found')
                            commit(mutations.SET_PATIENT_LOADING, 3)
                        }
                    })
                } else {
                    // There is a patient
                    resolve('Patient is checked in')
                    commit(mutations.SET_PATIENT_LOADING, 3)
                }
            })
        },

        getAllPatients({ commit }) {
            let patients = []
            let patientIds = []

            // retrieve a collection
            db.collection('patients')
                .get()
                .then(querySnapshot => {
                    // Take the docs from server reply
                    const documents = querySnapshot.docs.map(doc => doc.data())

                    _.forEach(documents, function(patient, key) {
                        patients.push({
                            value: patient.patientId,
                            label: `${patient.patientId} | ${patient.firstname} ${patient.lastname}`,
                        })
                        patientIds.push(patient.patientId)
                    })

                    // COMIT TO STORE
                    commit(mutations.SET_ALL_PATIENTS, patients)
                    commit(mutations.SET_ALL_PATIENT_IDS, patientIds)
                })
                .catch(() => {
                    // ERROR HANDLING
                    //console.error('Error while loading Patients: ', error)
                })
        },

        /****************************
         * Save Patient in Database
         *
         * @description: Adds new patient info
         *
         * @return Promise
         *
         * **********************************************/
        savePatient({ commit, state }) {
            // Promise
            return new Promise((resolve, reject) => {
                commit(mutations.SET_PATIENT_LOADING, 1)

                let patient = state.currentPatient

                // ADD createdAt
                patient.updatedAt = firebase.firestore.Timestamp.fromDate(
                    new Date()
                )

                // Clean Patient
                if ('measurements' in patient) {
                    delete patient.measurements
                }

                // Update Database
                db.collection('patients')
                    .doc(patient.patientId)
                    .set(patient)
                    .then(() => {
                        commit(mutations.SET_PATIENT_LOADING, 2)
                        resolve()
                    })
                    .catch(error => {
                        commit(mutations.SET_PATIENT_LOADING, 3)
                        reject(error)
                    })
            })
        },

        /****************************
         * Set Patient Data
         *
         * @description: Adds new patient info
         *
         * @return Void
         * **********************************************/
        addPatientData({ commit, state }, payload) {
            commit(mutations.SET_PATIENT_LOADING, 1)

            const { currentPatient } = state

            // ADD updatedAt
            currentPatient.updatedAt = firebase.firestore.Timestamp.fromDate(
                new Date()
            )

            // Commit
            commit(mutations.ADD_PATIENT_DATA, payload)
            commit(mutations.SET_PATIENT_LOADING, 2)
        },

        /****************************
         * getMeasurementsForPatient
         *
         * @description: Loads last 10 measurements of a patient and adds it to the store
         *
         * @return Void
         *
         * **********************************************/
        getMeasurements({ commit, getters, dispatch }) {
            const patientId = getters.currentPatientId
            let measurements = []

            dispatch(
                measurementStore.actionTypes.getMeasurementsForPatientId,
                patientId,
                { root: true },
                patientId
            )
                .then(data => {
                    measurements = data
                    // COMMIT TO STORE
                    commit(mutations.ADD_PATIENTS_MEASUREMENTS, measurements)
                })
                .catch(error => {
                    // this.$toast.error('Unable to load measurements')

                    // ERROR HANDLING
                    commit(mutations.ADD_PATIENTS_MEASUREMENTS, [])
                })
        },

        /****************************
         * Set Measurement
         *
         * @description: Set Measurements for Patient
         *
         * @return Void
         *
         * **********************************************/
        setMeasurements({ commit }, payload) {
            if (payload) {
                commit(mutations.ADD_PATIENTS_MEASUREMENTS, payload)
            }
        },
    },
    getters: {
        hasActivePatient: state =>
            _.size(state.currentPatient) ? true : false,
        currentPatientId: state =>
            state.currentPatient ? state.currentPatient.patientId : null,
        getCurrentPatient: state =>
            state.currentPatient ? state.currentPatient : null,
        getMeasurements: ({ currentPatient }) =>
            currentPatient !== null ? currentPatient.measurements : null,
        hasMeasurements: state =>
            'measurements' in state.currentPatient &&
            state.currentPatient.measurements.length > 0,
        patientLoading: state => state.loading === 1,
    },
}

listAllActionsOfStore(patient, 'patient')
listAllGettersOfStore(patient, 'patient')

export default patient
