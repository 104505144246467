/**
 * used to make "normal JSON" from observable object
 */
const toJson = store => JSON.parse(JSON.stringify(store))

/**
 * used to make a "object-like" naming for all actions of given store
 * @param {string} store - store object
 * @param {string} storename - storename used as a `prefix` with all names, so it maps vuex
 */
const listAllActionsOfStore = (store, storeName) => {
    store.actionTypes = {}
    Object.keys(store.actions).forEach(node => {
        store.actionTypes[node] = `${storeName}/${node}`
    })
}

/**
 * used to make a "object-like" naming for all getters of given store
 * @param {string} store - store object
 * @param {string} storename - storename used as a `prefix` with all names, so it maps vuex
 */
const listAllGettersOfStore = (store, storeName) => {
    store.getterTypes = {}
    Object.keys(store.getters).forEach(node => {
        store.getterTypes[node] = `${storeName}/${node}`
    })
}

export { listAllActionsOfStore, listAllGettersOfStore, toJson }
