<template>
    <div>
        <UnicolorLogo
            alt="Deep Breath Iniative"
            class="w-full h-auto fill-current"
            :class="textColor"
            data-cypress="logo"
        />
    </div>
</template>

<script>
import UnicolorLogo from '@/assets/img/UnicolorLogo.svg'

/**
 * @group ATOMS
 * DBI Logo as SVG
 */
export default {
    components: {
        UnicolorLogo,
    },
    inheritAttrs: true,
    props: {
        // Text-Color (e.g. text-primary-700)  from TailwindCSS that defines the color of the SVG.
        textColor: {
            type: String,
            default: 'text-primary-700',
        },
    },
}
</script>
