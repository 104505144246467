/*
|-------------------------------------------------------------------------------
| VUEX MODULE
|-------------------------------------------------------------------------------
| Authentication Events
*/
import { router, appStartRoute } from '@/router'
import { fb } from '@/services/firebase'
import { listAllActionsOfStore, listAllGettersOfStore } from 'helpers/common'
import userStore from 'store/user'

const mutations = {
    SET_LOADING_STATUS: 'SET_LOADING',
    SET_AUTH_STATUS: 'SET_AUTH_STATUS',
    SET_ERROR: 'SET_ERROR',
}
/**
 * status = 0 -> No loading has begun
 * status = 1 -> Loading has started
 * status = 2 -> Loading completed successfully
 * status = 3 -> Loading completed unsuccessfully
 *  */
const loadingStates = [0, 1, 2, 3]

const auth = {
    namespaced: true,

    state: {
        error: [],
        loggedIn: false,
        loadingStatus: loadingStates[0],
    },

    mutations: {
        // Sets a loading state
        [mutations.SET_LOADING_STATUS](state, n) {
            // Check Validity
            if (loadingStates.includes(n)) {
                state.loadingStatus = loadingStates[n]
            } else {
                throw 'Invalid Loading State'
            }
        },

        // Save Auth Status
        [mutations.SET_AUTH_STATUS](state, authStatus) {
            state.loggedIn = authStatus
        },
        // Set the error message
        // Description: Timestamp is important to distinguish new error with same text
        [mutations.SET_ERROR](state, err) {
            state.error = err
            state.error.timestamp = Date.now()
        },
    },
    actions: {
        init({ commit }) {
            // Reset the process
            commit(mutations.SET_LOADING_STATUS, 0)
            commit(mutations.SET_ERROR, [])
            commit(mutations.SET_AUTH_STATUS, false)
        },

        authStateChanged({ commit, dispatch }, user) {
            const isLoggedIn = user !== null
            commit(mutations.SET_AUTH_STATUS, isLoggedIn)
            dispatch(userStore.actionTypes.setCurrentUser, user, { root: true })
        },

        fetchCurrentUser({ commit, dispatch }) {
            const user = fb.auth().currentUser

            if (user !== null) {
                commit(mutations.SET_AUTH_STATUS, true)
                dispatch(userStore.actionTypes.setCurrentUser, user, {
                    root: true,
                })
            }
        },

        // Login the user and handle potential error states
        async login({ commit }, credentials) {
            // Indicate that the app is now loading
            commit(mutations.SET_LOADING_STATUS, 1)

            // Set the Persistence to 'Session'
            // This means that a user gets logged out when closing the window
            try {
                // Existing and future Auth states are now persisted in the current
                // session only. Closing the window would clear any existing state even if
                // a user forgets to sign out.
                await fb.auth().setPersistence('session')
            } catch (err) {
                commit(mutations.SET_ERROR, err)
                commit(mutations.SET_LOADING_STATUS, 3)
            }
            // Sign In
            try {
                await fb
                    .auth()
                    .signInWithEmailAndPassword(
                        credentials.email,
                        credentials.password
                    )
                commit(mutations.SET_AUTH_STATUS, true)
                router.push({ name: appStartRoute })
                commit(mutations.SET_LOADING_STATUS, 2)
            } catch (err) {
                commit(mutations.SET_ERROR, err)
                commit(mutations.SET_LOADING_STATUS, 3)
            }
        },

        //Logout
        async logout({ commit, dispatch }) {
            // Indicate that the app is now loading
            commit(mutations.SET_LOADING_STATUS, 1)
            try {
                await fb.auth().signOut()
                dispatch('authStateChanged', null)
                commit(mutations.SET_AUTH_STATUS, false)
                commit(mutations.SET_LOADING_STATUS, 2)
            } catch (err) {
                commit(mutations.SET_ERROR, err)
                commit(mutations.SET_LOADING_STATUS, 3)
            }
        },

        // Register a new user account
        async register({ commit, dispatch }, credentials) {
            // Indicate that the app is now loading
            commit(mutations.SET_LOADING_STATUS, 1)
            try {
                await fb
                    .auth()
                    .createUserWithEmailAndPassword(
                        credentials.email,
                        credentials.password
                    )
                // User logged in automatically by Firebase
                commit(mutations.SET_AUTH_STATUS, true)
                commit(mutations.SET_LOADING_STATUS, 2)
                // Send Email
                dispatch('sendVerificationEmail')
            } catch (err) {
                commit(mutations.SET_ERROR, err)
                commit(mutations.SET_LOADING_STATUS, 3)
            }
        },

        // Send Verification Email to current user
        async sendVerificationEmail() {
            const currentUser = await fb.auth().currentUser
            if (!currentUser) {
                return
            }

            try {
                currentUser.sendEmailVerification()
            } catch (err) {
                throw new Error('FAIL: sendVerificationEmail - no user sign in')
            }
        },

        async passwordReset({ commit, dispatch }, email) {
            // Indicate that the app is now loading
            commit(mutations.SET_LOADING_STATUS, 1)

            try {
                await fb.auth().sendPasswordResetEmail(email)
                // Email sent.
                // Set user to logged out
                dispatch('authStateChanged', null)
                commit(mutations.SET_AUTH_STATUS, false)
                commit(mutations.SET_LOADING_STATUS, 2)
            } catch (err) {
                commit(mutations.SET_ERROR, err)
                commit(mutations.SET_LOADING_STATUS, 3)
            }
        },
        // Redirect to a View
        verifyRedirect: () => router.replace({ name: 'VerifyEmail' }),
    },
    getters: {
        // hasEstates: (state, getters)
        //doneTodosCount: (state, getters) => {
        isLoggedIn: state => state.loggedIn,
        loading: state => state.loadingStatus === loadingStates[1],
        error: state => state.error,
    },
}

listAllActionsOfStore(auth, 'auth')
listAllGettersOfStore(auth, 'auth')

export default auth
