<script>
import Chart from 'chart.js'
Chart.defaults.global.defaultFontFamily = 'Mulish'
Chart.defaults.global.legend = false

import { Line } from 'vue-chartjs'

/**
 * @group MOLECULES
 * A wrapper for Chart.js to render a Line Chart in DBI Design
 */
export default {
    extends: Line,
    props: {
        // Data for the Chart as Object
        chartdata: {
            type: Object,
            default: null,
        },

        // Chart.js config Object
        options: {
            type: Object,
            default: null,
        },
    },

    mounted() {
        this.renderChart(this.chartdata, this.options)
    },
}
</script>
