var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.patient !== null)?_c('div',{attrs:{"data-cypress":"patient.characteristics"}},[_c('div',{staticClass:"top-block w-full"},[_c('div',{staticClass:"flex justify-between mb-3"},[_c('h3',{staticClass:"text-xl text-primary-700 mb-2 pb-0 self-end"},[_vm._v(" Patient Characteristics ")]),(_vm.actions)?[_c('basic-button',{attrs:{"color":"gray","icon":"user-slash","data-cypress":"patient.characteristics.checkout"},on:{"click":_vm.handlePatientCheckout}},[_vm._v(" Checkout Patient ")])]:_vm._e()],2),_c('div',{staticClass:"box mt-0"},[_c('div',{staticClass:"grid grid-cols-3"},[_c('div',{staticClass:"data"},[_c('table',_vm._l(({
                                firstname: 'First Name',
                                lastname: 'Last Name',
                                birthday: 'Age',
                                gender: 'Gender',
                            }),function(label,key){return _c('tr',{key:key},[_c('td',{staticClass:"pb-2"},[_c('span',{staticClass:"text-lg text-secondary-700"},[_vm._v(_vm._s(label))])]),_c('td',{staticClass:"pb-2"},[(key === 'birthday')?_c('span',{staticClass:"pl-6 font-bold text-lg text-primary-600"},[_vm._v(_vm._s(_vm._f("age")(_vm.patient[key])))]):_c('span',{staticClass:"pl-6 font-bold text-lg text-primary-600"},[_vm._v(_vm._s(_vm.patient[key]))])])])}),0),_c('edit-pill',{attrs:{"to":{
                            name: 'Demographics',
                            params: { patientId: _vm.patient.patientId },
                        }}})],1),_c('div',{staticClass:"diagnosis "},[_c('h3',{staticClass:"text-lg text-secondary-700"},[_vm._v(" Diagnosis ")]),('diagnoses' in _vm.patient)?_c('div',_vm._l((_vm.patient.diagnoses),function(diagnosis,index){return _c('div',{key:index},[(diagnosis.code)?_c('p',{staticClass:"text-secondary-700 p-1 px-3 bg-secondary-100 rounded inline-block my-1"},[(diagnosis && 'date' in diagnosis)?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:(diagnosis.date),expression:"diagnosis.date",modifiers:{"left":true}}]},[_c('font-awesome-icon',{staticClass:"mx-1 text-secondary-600",attrs:{"icon":['fas', 'calendar']}})],1):_c('a',[_c('font-awesome-icon',{staticClass:"mx-1 text-secondary-600",attrs:{"icon":['fas', 'calendar']}})],1),_vm._v(" ICD "+_vm._s(diagnosis.code)+" ")]):_vm._e()])}),0):_vm._e(),_c('edit-pill',{attrs:{"to":{
                            name: 'Diagnoses',
                            params: { patientId: _vm.patient.patientId },
                        }}})],1),_c('div',{staticClass:"medication ml-5"},[_c('h3',{staticClass:"text-lg text-secondary-700"},[_vm._v(" Medication ")]),('medication' in _vm.patient)?_c('div',_vm._l((_vm.patient.medication),function(medication,index){return _c('div',{key:index},[(medication.activeSubstance)?_c('p',{staticClass:"text-secondary-700 p-1 px-3 bg-secondary-100 rounded inline-block my-1"},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                        _vm.getMedicationTooltip(medication)
                                    ),expression:"\n                                        getMedicationTooltip(medication)\n                                    "}]},[_c('font-awesome-icon',{staticClass:"mx-1 text-secondary-600",attrs:{"icon":['fas', 'pills']}})],1),_vm._v(" "+_vm._s(medication.tradeName)+" ")]):_vm._e()])}),0):_vm._e(),_c('edit-pill',{attrs:{"to":{
                            name: 'Medication',
                            params: { patientId: _vm.patient.patientId },
                        }}})],1)])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }