export default store => {
    // called when the store is initialized

    // MUTATION SUBSCRIBER
    store.subscribe(mutation => {
        // called after every mutation.
        // The mutation comes in the format of `{ type, payload }`.

        if (mutation.type === 'patient/SET_CURRENT_PATIENT') {
            // Start getting subcollection if a user was set
            if (mutation.payload) {
                store.dispatch('patient/getMeasurements')
            }
        }
    })
}
