<template>
    <div class="dropdown-menu popover" data-cypress="navbar.dropdownMenu">
        <router-link
            v-for="item in menu"
            :key="item.key"
            :to="{ name: item.routeName }"
            :data-cypress="item.dataCypress || ''"
            class="py-3 px-8 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 no-underline text-lg hover:text-primary-600 hover:bg-primary-100"
        >
            <template v-if="item.icon">
                <font-awesome-icon
                    :icon="['fas', item.icon]"
                ></font-awesome-icon>
            </template>
            {{ item.title }}
        </router-link>
    </div>
</template>

<script>
/**
 * @group ATOMS
 * Renders the popover content as list with optional icon
 */
export default {
    $inheritAttrs: true,
    props: {
        // Menu Array with link Objects for Vue Router with item.routeName, item.icon, item.title
        menu: {
            type: Array,
            required: true,
        },
    },

    methods: {},
}
</script>
