<template>
    <light-template
        ><div class="p-10">
            Go to <router-link :to="{ name: 'Login' }">Login</router-link>
        </div>
    </light-template>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Home',
    components: {},

    mounted() {
        this.$router.push({
            name: 'Login',
        })
    },
}
</script>
