<template>
    <div v-if="status">
        <div>
            <div
                v-tooltip.left="'Status: ' + status.message"
                class="circle w-4 h-4 bg-gray-700 rounded-full"
                :class="{
                    'bg-green-500': status.code === 200,
                    'bg-yellow-500 animate-ping':
                        status.code === 204 || status.code === 201,
                    'bg-red-500': status.code > 300 || status.error,
                }"
            ></div>
        </div>
    </div>
</template>

<script>
/**
 * @group ATOMS
 * Measurement Status
 */
export default {
    inheritAttrs: true,

    props: {
        // Status of the measurment. The value defines the color.
        status: {
            // `OBJECT`
            // Type is measurement status object (status.code, status.message)
            type: Object,
            default: null,
        },
    },
}
</script>
