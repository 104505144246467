var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stepper mb-12 max-w-4xl mx-auto"},[_c('div',{staticClass:"p-5"},[_c('div',{staticClass:"mx-4 p-4"},[_c('div',{staticClass:"flex items-center text-center leading-none"},[_vm._l((_vm.steps),function(step){return [_c('div',{key:step.number,staticClass:"flex items-center relative"},[_c('div',{staticClass:"step rounded-full transition duration-500 ease-in-out h-16 w-16 py-3  mx-2 flex justify-center",class:{
                                active: _vm.isActive(step.number),
                                completed: _vm.isCompleted(step.number),
                            }},[_c('span',{staticClass:"stepnumber text-lg font-bold text-white inline-block self-center"},[_vm._v(_vm._s(step.number))])]),_c('div',{staticClass:"label absolute top-0 -ml-6 text-center mt-20 w-32 text-xs ",class:{
                                active: _vm.isActive(step.number),
                                completed: _vm.isCompleted(step.number),
                            }},[_vm._v(" "+_vm._s(step.label)+" ")])]),(!_vm.isLast(step.number))?_c('div',{key:step.label,staticClass:"line flex-auto border-t-2 transition duration-500 ease-in-out ",class:{
                            active: _vm.isActive(step.number),
                            completed: _vm.isCompleted(step.number),
                        }}):_vm._e()]})],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }