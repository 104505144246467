<template>
    <button
        class="m-button"
        type="button"
        :class="[cssClasses]"
        :disabled="loading || disabled"
        v-on="$listeners"
        @click="clickHandler"
    >
        <template v-if="icon && !iconRight && !loading">
            <font-awesome-icon :icon="['fas', icon]" class="mr-2" />
        </template>

        <template v-if="loading">
            <LoadingSpinner class="" />
        </template>
        <template v-else>
            <!-- Button Text -->
            <slot> <!-- `null` --></slot>
        </template>

        <template v-if="icon && iconRight && !loading">
            <font-awesome-icon :icon="['fas', icon]" class="ml-2" />
        </template>
    </button>
</template>

<script>
/**
 * @group ATOMS
 * Component to render a nice button
 */
export default {
    $inheritAttrs: true,
    props: {
        /**
         * Loading state of the button with a spinner (it's disabled while loading).
         * The used spinner is defined in LoadingSpinner.
         */
        loading: {
            type: Boolean,
            default: false,
        },
        // Disables the button so it cannot be clicked.
        disabled: {
            type: Boolean,
            default: false,
        },

        // Adds an icon to the button from FAS. Icon must be loaded manually in the fontAwesome.js
        icon: {
            type: String,
            default: null,
        },
        // Per default, the icon is on the left. Set this to true if you want the icon on the right.
        iconRight: {
            type: Boolean,
            default: false,
        },
        // Vue Router link. Example: `to="{name: 'someRouteName'}"`
        to: {
            type: Object,
            default: null,
        },

        // Shorthand for Vue Router. Simply provide the the route name as String
        route: {
            type: String,
            default: null,
        },

        // Color of the button. Can either be `gray`, `red` or `primary`
        color: {
            type: String,
            default: 'primary',
        },
    },

    computed: {
        cssClasses() {
            let cssArray = []
            cssArray.push(this.color)

            //  ADD LOADING CLASS
            if (this.loading) {
                cssArray.push('loading')
            }
            //  ADD DISABLED CLASS
            if (this.disabled) {
                cssArray.push('disabled')
            }

            return cssArray
        },
    },
    methods: {
        clickHandler() {
            if (this.route) {
                this.$router.push({ name: this.capitalize(this.route) })
            } else if (this.to) {
                this.$router.push(this.to)
            }
        },

        capitalize: s => {
            if (typeof s !== 'string') return s
            return s.charAt(0).toUpperCase() + s.slice(1)
        },
    },
    /// ADD Support for To:
    // <router-link :to="{ name: 'user', params: { userId: 123 } }"      >User</router-link       >
}
</script>

<style lang="scss">
.m-button {
    @apply bg-primary-500 shadow text-white h-12 leading-none uppercase font-bold text-lg py-4 px-10 rounded-lg transition duration-300 ease-in-out w-auto min-w-40 text-center;

    &:hover {
        @apply bg-primary-700 shadow-lg  outline-none;
    }

    &:focus {
        @apply shadow-md bg-primary-600 outline-none;
    }

    &:active {
        @apply bg-primary-600 scale-95  outline-none;
    }

    &.narrow {
        @apply px-2 min-w-0;
    }

    // COLOR VARIANTS ///////////////////////////////////
    &.red {
        @apply bg-red-600 text-red-100;

        &:hover {
            @apply bg-red-700;
        }
    }

    &.gray {
        @apply bg-gray-600 text-gray-300;

        &:hover {
            @apply bg-gray-700;
        }
    }

    // SPECIFIC STATES ///////////////////////////////////

    &.disabled {
        @apply cursor-not-allowed bg-gray-400 text-gray-100 shadow-none;
    }

    &.disabled:hover {
        @apply cursor-not-allowed bg-gray-400 text-gray-100 shadow-none;
    }

    &.loading {
        @apply opacity-75;
    }

    &.loading:hover {
        @apply cursor-not-allowed;
    }
}
</style>
