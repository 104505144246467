<template>
    <div class="stepper mb-12 max-w-4xl mx-auto">
        <div class="p-5">
            <div class="mx-4 p-4">
                <div class="flex items-center text-center leading-none">
                    <template v-for="step in steps">
                        <div
                            :key="step.number"
                            class="flex items-center relative"
                        >
                            <div
                                class="step rounded-full transition duration-500 ease-in-out h-16 w-16 py-3  mx-2 flex justify-center"
                                :class="{
                                    active: isActive(step.number),
                                    completed: isCompleted(step.number),
                                }"
                            >
                                <span
                                    class="stepnumber text-lg font-bold text-white inline-block self-center"
                                    >{{ step.number }}</span
                                >
                            </div>
                            <div
                                class="label absolute top-0 -ml-6 text-center mt-20 w-32 text-xs "
                                :class="{
                                    active: isActive(step.number),
                                    completed: isCompleted(step.number),
                                }"
                            >
                                {{ step.label }}
                            </div>
                        </div>
                        <div
                            v-if="!isLast(step.number)"
                            :key="step.label"
                            class="line flex-auto border-t-2 transition duration-500 ease-in-out "
                            :class="{
                                active: isActive(step.number),
                                completed: isCompleted(step.number),
                            }"
                        ></div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * @group MOLECULES
 * A stepper component
 */
export default {
    inheritAttrs: true,
    props: {
        // Active step (1-based)
        activeStep: {
            type: Number,
            default: 1,
        },

        /* Steps as Array of objects. Example: 
         `steps: [
                { label: 'Demographics', number: 1 },...
            ],`
        */
        steps: {
            type: Array,
            required: true,
        },
    },

    methods: {
        isActive(number) {
            return this.activeStep == number ? true : false
        },

        isCompleted(number) {
            return number <= this.activeStep ? true : false
        },

        isLast(number) {
            return this.steps.length == number ? true : false
        },
    },
}
</script>

<style lang="scss" scoped>
.step {
    @apply bg-secondary-100;

    &.completed {
        @apply bg-primary-700;
    }

    &.active {
        @apply bg-primary-600;
    }
}

.line {
    @apply border-secondary-200;
    .completed {
        @apply border-primary-600;
    }
    .active {
    }
}

.label {
    @apply text-secondary-200;

    &.completed {
        @apply text-primary-700;
    }
    &.active {
        @apply text-secondary-600;
    }
}
</style>
