<template>
    <div v-if="patient !== null" data-cypress="patient.characteristics">
        <div class="top-block w-full">
            <div class="flex justify-between mb-3">
                <h3 class="text-xl text-primary-700 mb-2 pb-0 self-end">
                    Patient Characteristics
                </h3>
                <template v-if="actions">
                    <basic-button
                        color="gray"
                        icon="user-slash"
                        data-cypress="patient.characteristics.checkout"
                        @click="handlePatientCheckout"
                    >
                        Checkout Patient
                    </basic-button>
                </template>
            </div>
            <div class="box mt-0">
                <div class="grid grid-cols-3">
                    <div class="data">
                        <table>
                            <tr
                                v-for="(label, key) in {
                                    firstname: 'First Name',
                                    lastname: 'Last Name',
                                    birthday: 'Age',
                                    gender: 'Gender',
                                }"
                                :key="key"
                            >
                                <td class="pb-2">
                                    <span class="text-lg text-secondary-700">{{
                                        label
                                    }}</span>
                                </td>
                                <td class="pb-2">
                                    <span
                                        v-if="key === 'birthday'"
                                        class="pl-6 font-bold text-lg text-primary-600"
                                        >{{ patient[key] | age }}</span
                                    >

                                    <span
                                        v-else
                                        class="pl-6 font-bold text-lg text-primary-600"
                                        >{{ patient[key] }}</span
                                    >
                                </td>
                            </tr>
                        </table>

                        <edit-pill
                            :to="{
                                name: 'Demographics',
                                params: { patientId: patient.patientId },
                            }"
                        ></edit-pill>
                    </div>
                    <div class="diagnosis ">
                        <h3 class="text-lg text-secondary-700">
                            Diagnosis
                        </h3>
                        <div v-if="'diagnoses' in patient">
                            <div
                                v-for="(diagnosis, index) in patient.diagnoses"
                                :key="index"
                            >
                                <p
                                    v-if="diagnosis.code"
                                    class="text-secondary-700 p-1 px-3 bg-secondary-100 rounded inline-block my-1"
                                >
                                    <a
                                        v-if="diagnosis && 'date' in diagnosis"
                                        v-tooltip.left="diagnosis.date"
                                    >
                                        <font-awesome-icon
                                            :icon="['fas', 'calendar']"
                                            class="mx-1 text-secondary-600"
                                        ></font-awesome-icon
                                    ></a>

                                    <a v-else>
                                        <font-awesome-icon
                                            :icon="['fas', 'calendar']"
                                            class="mx-1 text-secondary-600"
                                        ></font-awesome-icon
                                    ></a>
                                    ICD {{ diagnosis.code }}
                                </p>
                            </div>
                        </div>

                        <edit-pill
                            :to="{
                                name: 'Diagnoses',
                                params: { patientId: patient.patientId },
                            }"
                        ></edit-pill>
                    </div>
                    <div class="medication ml-5">
                        <h3 class="text-lg text-secondary-700">
                            Medication
                        </h3>
                        <div v-if="'medication' in patient">
                            <div
                                v-for="(medication,
                                index) in patient.medication"
                                :key="index"
                            >
                                <p
                                    v-if="medication.activeSubstance"
                                    class="text-secondary-700 p-1 px-3 bg-secondary-100 rounded inline-block my-1"
                                >
                                    <a
                                        v-tooltip="
                                            getMedicationTooltip(medication)
                                        "
                                    >
                                        <font-awesome-icon
                                            :icon="['fas', 'pills']"
                                            class="mx-1 text-secondary-600"
                                        ></font-awesome-icon
                                    ></a>
                                    {{ medication.tradeName }}
                                </p>
                            </div>
                        </div>

                        <edit-pill
                            :to="{
                                name: 'Medication',
                                params: { patientId: patient.patientId },
                            }"
                        ></edit-pill>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

/**
 * @group ORGANISMS
 * A box rendering the patient characteristics. Data is loaded from store.
 */
export default {
    props: {
        // The action bar (currently only Checkout of patient) will be displayed or not. Important when reusing this component at other locations.
        actions: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState({
            patient: state => state.patient.currentPatient,
        }),
    },
    methods: {
        // @vuese
        // Prepares the Tooptip
        // @arg The medication Array
        getMedicationTooltip({ activeSubstance, dosagePerDay }) {
            const substance = activeSubstance ? `${activeSubstance}` : ''
            const dosage = dosagePerDay
                ? ` | Dosage per Day: ${dosagePerDay} mg`
                : ''
            return `${substance}${dosage}`
        },
        handlePatientCheckout() {
            this.checkOutPatient().then(() => {
                this.$router.push({
                    name: 'PatientCheckIn',
                })
            })
        },
        ...mapActions('patient', ['checkOutPatient']),
    },
}
</script>

<style lang="scss" scoped>
.box {
    @apply p-6 rounded-lg bg-gray-200;
}
</style>
