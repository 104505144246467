<template>
    <div class="results-loading">
        <div class="message">
            <div
                class="flex w-full max-w-screen-md content-center justify-center  p-8 mx-auto mb-10 "
            >
                <LoadingSpinnerChase :width="60" class="mr-8" />
                <div class="">
                    <h1 class="text-primary-600 text-2xl mb-5 pb-0">
                        Results are getting calculated.
                    </h1>
                    <div class="text-xl text-gray-500">
                        <p>
                            Please be patient. This process usually takes
                            between 50 and 90 seconds. You can always come back
                            later.
                        </p>

                        <div class="pt-5">
                            <time-since
                                v-if="timestamp"
                                :starttime="timestamp"
                            ></time-since>
                        </div>
                    </div>

                    <div class="progress my-4">
                        <ProgressBar
                            v-if="hasProgress"
                            :progress="status.progress"
                            :message="status.message"
                        />
                    </div>
                </div>
            </div>
        </div>
        <results-skeleton-loader></results-skeleton-loader>
    </div>
</template>

<script>
import _ from 'lodash'

import { mapState, mapGetters } from 'vuex'

/**
 * @group ORGANISMS
 * Renders a loading screen with Spinner, ProgressBar and Skeleton Loader
 */
export default {
    $inheritAttrs: true,
    props: {},

    computed: {
        timestamp() {
            if (this.cloudTask && _.hasIn(this.cloudTask, 'timestamp')) {
                return this.cloudTask.timestamp
            } else {
                return false
            }
        },

        ...mapState('measurement', {
            cloudTask: state => state.active.cloudTask,
            status: state => state.active.status,
        }),

        ...mapGetters('measurement', ['hasProgress']),
    },
}
</script>
