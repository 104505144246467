<template>
    <router-link :to="to">
        <div
            class="bg-secondary-100 p-2 px-3 text-xs mt-4  font-bold uppercase text-secondary-500 inline-block rounded-full hover:text-primary-100 hover:bg-primary-500 transition-colors duration-300 ease-in-out"
        >
            <font-awesome-icon :icon="['fas', 'edit']" class="mr-2" />
            <span>Edit</span>
        </div>
    </router-link>
</template>

<script>
/**
 * @group ATOMS
 * An edit button with a pill
 */
export default {
    inheritAttrs: true,
    props: {
        // Vue Object link. Example: `to="{name: 'someRouteName'}"`
        to: {
            type: Object,
            default: null,
        },
    },
}
</script>
