<template>
    <div class="uploader ">
        <ProgressBar
            v-show="currentUpload.progress > 0 && !currentUpload.completed"
            :progress="currentUpload.progress ? currentUpload.progress : 0"
            :message="currentUpload.status ? currentUpload.status : ''"
        />
        <FormulateInput
            v-if="!currentUpload.completed"
            type="file"
            name="file"
            :label="label"
            data-cypress="dropzone"
            help="Select local RAW-File to upload"
            class="mt-5 max-w-md mx-auto text-xs overflow-auto"
            @change="detectFiles($event.target.files)"
        />
        <div
            v-if="currentUpload.completed"
            class="success-box bg-green-200 rounded-lg text-green-800 text-center p-4"
        >
            <div>
                <font-awesome-icon :icon="['fas', 'check-circle']" size="2x" />
            </div>
            <h4 class="text-base font-bold px-8 mt-4">
                Measurement file was uploaded successfully.
            </h4>
        </div>

        <div
            v-if="currentUpload.error"
            class="error-box bg-red-200 rounded-lg text-red-800 text-center p-4"
        >
            <div>
                <font-awesome-icon :icon="['fas', 'check-circle']" size="2x" />
            </div>
            <h4 class="text-sm font-bold pb-0 mt-4 mb-2 ">
                An error occured. Upload failed.
            </h4>
            <p class="text-sm mb-4">Status: {{ currentUpload.status }}</p>
            <basicButton color="gray" @click="resetUpload(iontype)"
                >Try again</basicButton
            >
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

/**
 * @group MOLECULES
 * Renders a dropzone on which files can be uploaded. The files will then be uploaded using the uploadFile Action from store/measurement
 */
export default {
    inheritAttrs: true,

    props: {
        // Label Text for the dropzone uploader
        label: {
            type: String,
            default: null,
        },
        // Ion Type of the upload (pos / neg)
        iontype: {
            // String with either `pos` or `neg`
            type: String,
            required: true,
        },
    },
    computed: {
        currentUpload() {
            return this.upload[this.iontype] ? this.upload[this.iontype] : null
        },

        ...mapState('measurement', {
            upload: state => state.upload,
        }),
    },
    methods: {
        detectFiles(fileList) {
            Array.from(Array(fileList.length).keys()).map(x => {
                this.uploadFile({ file: fileList[x], iontype: this.iontype })
            })
        },

        // Fileupload to Cloud
        ...mapActions('measurement', [
            'uploadFile', // -> this.uploadFile()
            'resetUpload',
        ]),
    },
}
</script>
