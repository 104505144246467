<template>
    <div class="app-card">
        <div
            v-scroll-reveal.reset
            class="flex flex-wrap  rounded-lg overflow-hidden shadow-md  my-4  border-2  transition-all duration-300 "
            :class="{
                'bg-gray-200 border-transparent cursor-not-allowed': disabled,
                'bg-secondary-100 border-primary-100  ': !disabled,
            }"
        >
            <div
                class="card-body h-48 w-full p-4 bg-cover flex"
                :style="{
                    'background-image': getBackgroundImage,
                }"
            >
                <div
                    class="app-title self-center bg-white w-full rounded-lg px-8 py-4 md:mx-4 text-2xl leading-tight tracking-normal font-bold text-center"
                    :class="{
                        'text-gray-500': disabled,
                        'text-primary-700': !disabled,
                    }"
                >
                    <slot></slot>
                </div>
            </div>

            <div
                class="card-footer w-full self-end bg-gray-200 shadow-inner p-4"
            >
                <div class="flex justify-between">
                    <div><DropdownButton v-if="!disabled" :menu="menu" /></div>
                    <div>
                        <basic-button
                            :data-cypress="'selectionCard.newButton.' + app"
                            icon="plus"
                            class="narrow"
                            :disabled="disabled"
                            :to="{
                                name: 'NewMeasurement',
                                params: {
                                    patientId: currentPatientId,
                                    appSlug: app,
                                },
                            }"
                            >New</basic-button
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
/**
 * @group MOLECULES
 * A nice little card to display an app (measurement type)
 */

export default {
    inheritAttrs: true,
    props: {
        // Disables the app and does not allow clicks
        disabled: {
            type: Boolean,
            default: false,
        },
        // An optional background image
        image: {
            type: String,
            default: null,
        },
        // ID-String of the app (e.g. valproate)
        app: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            menu: {
                export: { title: 'Export', name: 'Export' },
            },
        }
    },
    computed: {
        getBackgroundImage() {
            return this.image ? 'url(' + this.image + ')' : 'none'
        },

        ...mapGetters('patient', ['currentPatientId']),
    },
}
</script>
