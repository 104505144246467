<template>
    <div v-if="hasMeasurements && !patientLoading">
        <h3 class="text-md  text-gray-600">
            <span class="capitalize">{{ result }}</span> VPA
        </h3>
        <div class="past-measurements-component flex flex-row pt-0 relative">
            <LineChart
                :chartdata="chartData"
                :options="chartOptions"
                :styles="myStyles"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
/**
 * @group MOLECULES
 * Renders a Linechart with multiple measurements over time
 */
export default {
    props: {
        // The result
        result: {
            type: String,
            required: true,
        },

        // The therapeutic minimum
        therapeuticMin: {
            type: Number,
            required: true,
        },
        // The therapeutic maximum
        therapeuticMax: {
            type: Number,
            required: true,
        },

        // The min value
        min: {
            type: Number,
            default: 0,
        },

        // The max value
        max: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            height: 400,
            width: 1000,
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                fontFamily: 'Mulish',
                scales: {
                    xAxes: [
                        {
                            scaleLabel: {
                                display: true,
                                labelString: 'Date',
                            },
                            padding: 10,
                        },
                    ],
                    yAxes: [
                        {
                            scaleLabel: {
                                display: true,
                                labelString: 'mg/L',
                            },

                            ticks: {
                                suggestedMin: this.min,
                                suggestedMax: this.max,
                            },
                        },
                    ],
                    legend: {
                        display: false,
                    },
                },
            },
        }
    },
    computed: {
        chartData: function() {
            return {
                labels: this.chartLabels,
                datasets: [
                    {
                        label: 'Free VPA',
                        borderColor: '#9E42D7',
                        data: this.resultData,
                        fill: false,
                        borderWidth: 4,
                    },
                    {
                        label: 'Therapeutic Min',
                        fill: false,
                        borderColor: '#66CC66',
                        data: this.therapeuticMinValue,
                        borderDash: [5, 5],
                    },
                    {
                        label: 'Therapeutic Max',
                        fill: false,
                        borderColor: '#66CC66',
                        data: this.therapeuticMaxValue,
                        borderDash: [5, 5],
                    },
                ],
            }
        },

        myStyles() {
            return {
                width: `${this.width}px`,
                position: 'relative',
            }
        },

        chartLabels: function() {
            let labels = []
            _.forEach(this.getMeasurements, measurement => {
                // this.$options.filters.capitalize(this.word);
                if (measurement.completed) {
                    labels.push(
                        this.$options.filters.date(
                            measurement.completedAt.seconds
                        )
                    )
                }
            })
            return labels
        },

        resultData: function() {
            let data = []
            _.forEach(this.getMeasurements, measurement => {
                // this.$options.filters.capitalize(this.word);
                if (measurement.completed) {
                    data.push(
                        this.$options.filters.round(
                            measurement.results[this.result]
                        )
                    )
                }
            })
            // REVERSE

            data = _.reverse(data)

            return data
        },

        therapeuticMaxValue: function() {
            let data = []
            _.forEach(this.resultData, () => {
                data.push(this.therapeuticMax)
            })
            return data
        },
        therapeuticMinValue: function() {
            let data = []
            _.forEach(this.resultData, () => {
                data.push(this.therapeuticMin)
            })

            return data
        },

        ...mapGetters('patient', [
            'hasMeasurements',
            'getMeasurements',
            'patientLoading',
        ]),
    },
    methods: {
        // @vuese
        // Helper Method to incerase the height
        increase() {
            this.height += 10
        },
    },
}
</script>
