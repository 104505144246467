<template>
    <div>
        Process running since
        <span> {{ minutes }} minutes {{ seconds }} seconds</span>
    </div>
</template>

<script>
/**
 * @group ATOMS
 * Calcuates the running time since a given start date. Get's updated every second.
 */
export default {
    inheritAttrs: true,
    props: {
        // Timestamp of the start date
        starttime: {
            // `UNIX TIMESTAMP`
            type: Number,
            default: Date.now(),
        },
    },
    data() {
        return {
            interval: null,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            intervals: {
                second: 1000,
                minute: 1000 * 60,
                // hour: 1000 * 60 * 60,
                // day: 1000 * 60 * 60 * 24,
            },
        }
    },
    computed: {
        time() {
            return new Date(this.starttime)
        },
    },
    mounted() {
        this.interval = setInterval(() => {
            this.updateDiffs()
        }, 1000)

        this.updateDiffs()
    },
    destroyed() {
        clearInterval(this.interval)
    },
    methods: {
        updateDiffs() {
            //lets figure out our diffs
            let diff = Math.abs(Date.now() - this.time)
            // this.days = Math.floor(diff / this.intervals.day)
            //diff -= this.days * this.intervals.day
            //this.hours = Math.floor(diff / this.intervals.hour)
            //diff -= this.hours * this.intervals.hour
            this.minutes = Math.floor(diff / this.intervals.minute)
            diff -= this.minutes * this.intervals.minute
            this.seconds = Math.floor(diff / this.intervals.second)
        },
    },
}
</script>

<style lang="scss" scoped></style>
