<template>
    <div>
        <div v-scroll-reveal class="flex justify-between flex-wrap">
            <h2
                class="text-xl  text-secondary-700"
                data-cypress="measurement.results.concentrationOf"
            >
                Concentration of {{ substance }}
            </h2>
            <h6 class="text-xs lg:text-xl text-secondary-700">
                {{ measurement.completedAt.seconds | datetime }}
            </h6>
        </div>
        <div v-scroll-reveal class="flex justify-between flex-wrap">
            <div class="value-box w-full md:w-1/4 p-4 flex-shrink-0">
                <div class="menu-left">
                    <div class="menu-left__block">
                        <div class="my-5">
                            <p
                                class="vpa"
                                :class="{ 'vpa--green': resultsInterpretation }"
                            >
                                {{ measurement.results.total | round }} mg/L
                            </p>
                            <p
                                class="uppercase text-xl text-gray-500 tracking-wider text-center "
                            >
                                Total VPA
                            </p>
                        </div>
                        <div class="my-5">
                            <p
                                class="vpa"
                                :class="{ 'vpa--green': resultsInterpretation }"
                            >
                                {{ measurement.results.free | round }} mg/L
                            </p>
                            <p
                                class="uppercase text-xl text-gray-500 tracking-wider text-center"
                            >
                                Free VPA
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    v-if="
                        'medication' in measurement &&
                            Object.keys(measurement.medication[0]).length !== 0
                    "
                >
                    <h2 class="menu-left__header">
                        Medication &amp; Diagnosis
                    </h2>
                    <div class="menu-left">
                        <div class="menu-left__block">
                            <div
                                v-if="measurement.medication[0].dosagePerIntake"
                                class="my-2"
                            >
                                <p class="menu-left__stat">
                                    {{
                                        measurement.medication[0]
                                            .dosagePerIntake | dosage
                                    }}
                                    mg/kg/d
                                </p>
                                <p class="menu-left__stat-description">
                                    current vpa dosage
                                </p>
                            </div>
                        </div>
                        <div
                            v-if="'code' in measurement.medication[0]"
                            class="menu-left__block"
                        >
                            <div
                                v-if="measurement.diagnoses[0].code"
                                class="my-2"
                            >
                                <p class="menu-left__stat">
                                    ICD
                                    {{
                                        measurement.diagnoses[0]
                                            ? measurement.diagnoses[0].code
                                            : ''
                                    }}
                                </p>
                                <p class="menu-left__stat-description">
                                    diagnosis
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h2 class="menu-left__header">
                        Side Effects &amp; Seizures
                    </h2>
                </div>
                <div class="menu-left">
                    <div class="menu-left__block">
                        <div
                            v-if="'sideEffects' in measurement.results"
                            class="my-2"
                        >
                            <p class="menu-left__stat">
                                {{
                                    (measurement.results.sideEffects * 100)
                                        | round
                                }}
                                %
                            </p>
                            <p class="menu-left__stat-description">
                                Side-Effects
                                <a
                                    v-tooltip.left="
                                        'Likelihood for Side-Effects'
                                    "
                                >
                                    <font-awesome-icon
                                        :icon="['fas', 'info-circle']"
                                        class="mx-1"
                                /></a>
                            </p>
                        </div>
                        <div
                            v-if="'seizures' in measurement.results"
                            class="my-2"
                        >
                            <p class="menu-left__stat">
                                {{
                                    (measurement.results.seizures * 100) | round
                                }}
                                %
                            </p>
                            <p class="menu-left__stat-description">
                                Seizures
                                <a v-tooltip.left="'Likelihood for Seizures'">
                                    <font-awesome-icon
                                        :icon="['fas', 'info-circle']"
                                        class="mx-1"
                                /></a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="chart-box w-full md:w-3/4 p-8">
                <LinearGauge
                    title="Total VPA"
                    unit="(mg/L)"
                    info="Therapeutic Range 50 - 100 (mg/L)"
                    :min="0"
                    :max="150"
                    :therapeutic-min="50"
                    :therapeutic-max="100"
                    :value="measurement.results.total"
                />
                <LinearGauge
                    title="Free VPA"
                    unit="(mg/L)"
                    info="Therapeutic Range  5 - 10 (mg/L)"
                    :min="0"
                    :max="15"
                    :therapeutic-min="5"
                    :therapeutic-max="10"
                    :value="measurement.results.free"
                />
                <h3 class="right-panel__header">
                    Further Patient Information
                </h3>

                <div>
                    <FormulateInput
                        v-model="measurement.sideEffects"
                        type="text"
                        name="sideEffects"
                        label="Side Effects"
                    />
                    <div class="flex flex-wrap">
                        <div class="w-full md:w-1/2 md:pr-5">
                            <FormulateInput
                                v-model="measurement.seizuresNumber"
                                type="text"
                                name="seizuresNumber"
                                label="Number of Seizures (Current Year)"
                            />
                        </div>
                        <div class="w-full md:w-1/2 ">
                            <FormulateInput
                                v-model="measurement.eeg"
                                type="select"
                                :options="{
                                    normal: 'Normal',
                                    abnormal: 'Abnormal',
                                }"
                                placeholder=""
                                label="EEG"
                                name="eeg"
                                value=""
                            />
                        </div>
                    </div>
                </div>

                <h3 v-if="hasMeasurements" class="right-panel__header">
                    Past Results
                </h3>

                <MeasurementsOverTime
                    v-scroll-reveal
                    result="total"
                    :therapeutic-min="50"
                    :therapeutic-max="100"
                    :min="0"
                    :max="150"
                />
                <MeasurementsOverTime
                    v-scroll-reveal
                    result="free"
                    :therapeutic-min="5"
                    :therapeutic-max="10"
                    :min="0"
                    :max="15"
                />
            </div>
        </div>
        <div
            v-scroll-reveal
            class="meta-box w-full lg:w-1/4  my-5  flex-shrink-0"
        >
            <h2 class="menu-left__header">
                Measurement Meta Data
            </h2>
            <div class="menu-left p-4">
                <p class="metadata">
                    <span class="metadata__unit">Created on:</span>
                    <span class="metadata__value">{{
                        measurement.createdAt.seconds | date
                    }}</span>
                </p>
                <p class="metadata">
                    <span class="metadata__unit">Created at:</span>
                    <span class="metadata__value">{{
                        measurement.createdAt.seconds | time
                    }}</span>
                </p>
                <p class="metadata">
                    <span class="metadata__unit">Completed on:</span>
                    <span class="metadata__value">{{
                        measurement.completedAt.seconds | date
                    }}</span>
                </p>
                <p class="metadata">
                    <span class="metadata__unit">Completed at:</span>
                    <span class="metadata__value">{{
                        measurement.completedAt.seconds | time
                    }}</span>
                </p>
                <p class="metadata">
                    <span class="metadata__unit">Analysis runtime:</span>
                    <span class="metadata__value">{{ runtime }} sec.</span>
                </p>
                <p class="metadata">
                    <span class="metadata__unit">Device:</span>
                    <span class="metadata__value">{{
                        measurement.meta.IonSourceDevice
                    }}</span>
                </p>
            </div>
        </div>
        <MeasurementToolbar v-scroll-reveal :uuid="measurement.uuid" />
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import _ from 'lodash'
import { debounceTimers } from 'helpers/constants'
import { toJson } from 'helpers/common'
import { db } from '@/services/firebase'

/**
 * @group ORGANISMS
 * Big, bloated component that renders the Results page with complete results
 */
export default {
    computed: {
        additionalInfo() {
            const { sideEffects, seizuresNumber, eeg } = this.measurement
            return { sideEffects, seizuresNumber, eeg }
        },
        ...mapState('measurement', {
            measurement: state => state.active,
        }),
        ...mapState('patient', {
            patient: state => state.currentPatient,
        }),
        ...mapGetters('measurement', [
            'resultsInterpretation',
            'runtime',
            'hasMeasurements',
        ]),
        ...mapGetters('patient', ['hasMeasurements']),
        substance() {
            return _.get(
                this.measurement,
                'medication[0].activeSubstance',
                'Active Substance'
            )
        },
    },
    watch: {
        additionalInfo: {
            handler(newVal, oldVal) {
                if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
                    this.saveWithDebounce()
                }
            },
        },
    },
    created() {
        this.saveWithDebounce = _.debounce(function() {
            const { uuid } = this.measurement
            const { patientId } = this.patient

            db.collection('patients')
                .doc(patientId)
                .collection('measurements')
                .doc(uuid)
                .update(toJson(this.additionalInfo))
                .then(() => {
                    this.$toast.success(`Changes saved!`)
                })
                .catch(e => {
                    console.error(
                        `ResultsComplete.vue:this.saveWithDebounce problem: ${e}`
                    )
                    this.$toast.error('Problem with saving data.')
                })
        }, debounceTimers.default)
    },
    mounted() {
        this.getMeasurements()
    },
    methods: {
        ...mapActions('patient', ['getMeasurements']),
    },
}
</script>

<style lang="scss" scoped>
.menu-left {
    @apply rounded-lg  bg-gray-200;

    &__header {
        @apply text-lg mt-6 text-secondary-700;
    }

    &__block {
        @apply flex flex-col items-stretch content-around p-5;
    }

    &__stat {
        @apply text-2xl font-bold text-gray-600 text-center mb-2;
    }

    &__stat-description {
        @apply uppercase text-base text-gray-500 tracking-wider text-center;
    }
}

.vpa {
    @apply text-4xl font-bold text-gray-700 text-center mb-2;

    &--green {
        @apply text-green-500;
    }
}

.metadata {
    @apply text-gray-700 text-sm mb-1;

    .unit {
        @apply pr-2 font-bold;
    }
}

.right-panel {
    &__header {
        @apply text-lg mt-6 text-secondary-700;
    }
}
</style>
