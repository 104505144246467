<template>
    <default-app-template>
        <div>
            <basic-headline
                >Please perform breath measurement now.</basic-headline
            >

            <div class="max-w-xl mx-auto my-10">
                <img src="@/assets/img/breath.jpg" />
            </div>

            <div class="mx-auto w-full md:w-2/3 lg:w-1/3">
                <FormulateInput
                    v-model="done"
                    type="checkbox"
                    label="Breath measurement completed"
                    data-cypress="patient.breathMeasurement.checkbox"
                />
            </div>
            <div class="flex justify-end  mx-auto w-4/5">
                <basic-button
                    icon="arrow-right"
                    icon-right
                    :disabled="!done"
                    data-cypress="patient.breathMeasurement.submit"
                    @click.prevent="next()"
                    >Next</basic-button
                >
            </div>
        </div>
    </default-app-template>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            done: false,
            validation: {},
        }
    },
    computed: {
        ...mapState('measurement', {
            measurement: state => state.active,
        }),
    },

    mounted() {},
    methods: {
        next() {
            this.increaseStep()
            // Go to Named Route
            this.$router.push({
                name: 'UploadMeasurement',
                params: { uuid: this.measurement.uuid },
            })
        },
        // VUEX
        ...mapActions('measurement', ['increaseStep', 'decreaseStep']),
    },
}
</script>
