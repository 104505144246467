<template>
    <default-app-template>
        <basic-headline>Upload Measurement Files</basic-headline>

        <div v-if="hasFiles && !hasLocalFiles" class="mx-auto max-w-lg">
            <basic-notification type="info">
                <template v-slot:bold>Heads up.</template>
                There were already files uploaded. You can either upload
                measurement files again or continue starting the
                analysis.</basic-notification
            >
        </div>
        <FileUploader />

        <div class="flex justify-end">
            <basicButton
                :loading="loading"
                :disabled="!hasFiles"
                icon="arrow-right"
                icon-right
                data-cypress="upload.submit"
                @click="triggerTask()"
                >Start Analysis Process</basicButton
            >
        </div>
    </default-app-template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('measurement', ['hasFiles', 'hasLocalFiles']),
        ...mapGetters('ui', [
            'loading', // -> this.new()s
        ]),
    },

    created() {
        this.resetUpload()
    },
    methods: {
        triggerTask() {
            this.$toast.info(`Contacting Server...`)
            this.addCloudTask()
                .then(() => {
                    // Cloud task was created, let's redirect!

                    // Make Toast
                    this.$toast.success(
                        'Analysis Process started successfully.'
                    )

                    // Redirect
                    this.$router.push({
                        name: 'ShowResults',
                    })
                })
                .catch(err => {
                    this.$toast.error(err)
                })
        },

        // VUEX
        ...mapActions('measurement', ['addCloudTask', 'resetUpload']),
    },
}
</script>
