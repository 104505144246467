<template>
    <div
        :class="
            `formulate-input-element formulate-input-element--${context.type} relative`
        "
        :data-type="context.type"
    >
        <input
            v-model="context.model"
            type="text"
            v-bind="context.attributes"
            autocomplete="off"
            @keydown.down.prevent="increment"
            @keydown.up.prevent="decrement"
            @keydown.enter.prevent="enter()"
            @blur="context.blurHandler"
        />
        <ul
            v-if="filteredOptions.length"
            class="formulate-input-dropdown p-2 bg-gray-100 shadow-lg absolute w-full rounded-bl-lg rounded-br-lg z-1000"
        >
            <li
                v-for="(option, index) in filteredOptions.slice(0, 10)"
                :key="option.value"
                :data-is-selected="
                    selection && selection.value === option.value
                "
                class="text-gray-700 cursor-pointer  hover:bg-gray-200 px-2 py-2"
                :class="{
                    'bg-gray-200':
                        selection && selection.value === option.value,
                }"
                @mouseenter="selectedIndex = index"
                @click="context.model = selection.value"
                v-text="option.label"
            />
        </ul>
    </div>
</template>

<script>
/**
 * @group VUE-FORMULATE PLUGINS
 * Autocomplete Plugin for DBI. Please check: https://vueformulate.com/guide/plugins/
 */
export default {
    props: {
        // Context Object provided by Vue Formulate
        context: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            selectedIndex: 0,
        }
    },
    computed: {
        model() {
            return this.context.model
        },
        selection() {
            if (this.filteredOptions[this.selectedIndex]) {
                return this.filteredOptions[this.selectedIndex]
            }
            return false
        },
        filteredOptions() {
            if (Array.isArray(this.context.options) && this.context.model) {
                const isAlreadySelected = this.context.options.find(
                    option => option.value === this.context.model
                )
                if (!isAlreadySelected) {
                    return this.context.options.filter(option =>
                        option.label
                            .toLowerCase()
                            .includes(this.context.model.toLowerCase())
                    )
                }
            }
            return []
        },
    },
    watch: {
        model() {
            this.selectedIndex = 0
        },
    },
    methods: {
        enter() {
            // Set value of v-model
            if (this.selection.value) {
                this.context.model = this.selection.value
            } else {
                // Fire enter to so that parent component can listen
                this.$emit('enter')
            }
        },
        increment() {
            // Open autocomplete if "KEYDOWN" while String is empty
            if (this.model.length == 0) {
                this.context.model = ' '
            }

            const length = this.filteredOptions.length
            if (this.selectedIndex + 1 < length) {
                this.selectedIndex++
            } else {
                this.selectedIndex = 0
            }
        },
        decrement() {
            const length = this.filteredOptions.length
            if (this.selectedIndex - 1 >= 0) {
                this.selectedIndex--
            } else {
                this.selectedIndex = length - 1
            }
        },
    },
}
</script>
