<template>
    <div class="day-specific-conditions  my-10">
        <div class="w-full lg:w-1/3 mx-auto">
            <h4 class="text-secondary-700 font-bold text-left  text-xl">
                Did you in the past hour...?
            </h4>
        </div>
        <FormulateForm @submit="submit">
            <div class="w-full lg:w-1/3 mx-auto">
                <FormulateInput
                    v-model="selectedOptions"
                    type="checkbox"
                    :options="options"
                />
            </div>

            <!-- BUTTON -->
            <div class="flex justify-end max-w-screen-md mx-auto">
                <basic-button
                    class="mr-5"
                    color="gray"
                    data-cypress="patient.daySpecificConditions.back"
                    @click="back"
                    >Back</basic-button
                >
                <FormulateInput
                    type="submit"
                    label="Next"
                    data-cypress="patient.daySpecificConditions.submit"
                />
            </div>
            <!-- BUTTON -->
        </FormulateForm>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
/**
 * @group ORGANISMS
 * This is a form to ask for day specific conditions
 */
export default {
    data() {
        return {
            options: {
                eat: 'Eat',
                drink: 'Drink (exept water)',
                gum: 'Chew gum',
                smoke: 'Smoke',
                lipstick: 'Apply lipstick / balm',
                brush: 'Brush teeth',
            },
            selectedOptions: [],
        }
    },

    methods: {
        submit() {
            this.addData({ conditions: this.selectedOptions })
            // Fired when the submit button is clicked
            // @arg null
            this.$emit('next')
        },

        back() {
            // Fired when the back button is clicked
            // @arg null
            this.$emit('back')
        },

        ...mapActions('measurement', ['addData']),
    },
}
</script>
