<template>
    <div class="mt-8">
        <h3 class="text-xl text-primary-700 mb-2 pb-0">
            Available Tests
        </h3>

        <div class="grid grid-cols-1 md:grid-cols-3 md:gap-4">
            <app-selection-card app="valproate"
                >Valproic Acid (VPA)</app-selection-card
            >
            <app-selection-card app="feno" disabled>FeNO</app-selection-card>
            <app-selection-card app="copd" disabled>COPD</app-selection-card>
        </div>
    </div>
</template>

<script>
/**
 * @group ORGANISMS
 * This component is a simple wrapper two render 3 tests for the user. Only VALPROATE is clickable. The rest is dummy.
 */
export default {}
</script>

<style lang="scss" scoped></style>
