import { render, staticRenderFns } from "./PatientDataStepper.vue?vue&type=template&id=1944feb5&scoped=true&"
import script from "./PatientDataStepper.vue?vue&type=script&lang=js&"
export * from "./PatientDataStepper.vue?vue&type=script&lang=js&"
import style0 from "./PatientDataStepper.vue?vue&type=style&index=0&id=1944feb5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1944feb5",
  null
  
)

export default component.exports