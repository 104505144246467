import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/sass/style.scss'
import { fb } from '@/services/firebase'
import registerComponents from './components.js'
import filters from './utils/filters/filters.js'
import VueToast from 'vue-toast-notification'
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'

import VueScrollReveal from 'vue-scroll-reveal'

// Font-Awesome
import FontAwesomeIcon from '@/services/fontawesome'
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Vue Formulate

import VueFormulate from '@braid/vue-formulate'
import FormulateAutocomplete from '@/plugins/forms/FormulateAutocomplete'
import ICDAutocomplete from '@/plugins/forms/FormulateICDAutocomplete'
import FormulateDrugAutocomplete from '@/plugins/forms/FormulateDrugAutocomplete'

//import tailwindClasses from '@/plugins/forms/tailwindClasses'
import { validPatientId } from '@/plugins/forms/validationRules'

// Video Player
import VueCoreVideoPlayer from 'vue-core-video-player'

Vue.use(VueCoreVideoPlayer)

// Formulate Plugin
Vue.component('FormulateAutocomplete', FormulateAutocomplete)
Vue.component('ICDAutocomplete', ICDAutocomplete)
Vue.component('FormulateDrugAutocomplete', FormulateDrugAutocomplete)

Vue.use(VueFormulate, {
    rules: {
        validPatientId: validPatientId,
    },

    library: {
        autocomplete: {
            classification: 'text',
            component: 'FormulateAutocomplete',
        },
        icd: {
            classification: 'text',
            component: 'ICDAutocomplete',
        },
        drug: {
            classification: 'text',
            component: 'FormulateDrugAutocomplete',
            slotProps: {
                // Prop to decide which key should be used to display data in the dropdown
                component: ['displayKey', 'groupIndex'],
            },
        },
    },
})

// Using ScrollReveal's default configuration
Vue.use(VueScrollReveal, {
    scale: 0.9,
    duration: 500,
    distance: '10px',
})

// TOOLTIP
Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)
Vue.component('v-popover', VPopover)

// Register Filters
filters(Vue)

Vue.use(VueToast)

/****************************************************
 * Automically registers all
 * UI-components from   './components/ui',
 *************/
registerComponents(Vue)

Vue.config.productionTip = false

// The Vue App Instance
// It's a variable since we will integrate it with firebase's authChanged listener
let app = null

// Initally we trigger the fetch action
store.dispatch('auth/fetchCurrentUser')

// Before we mount the app, we have to register the Event Listener
// for any future Auth-Changes
fb.auth().onAuthStateChanged(user => {
    store.dispatch('auth/authStateChanged', user)

    // On inital load, we create the Vue Instance
    if (!app) {
        app = new Vue({
            router,
            store,
            render: h => h(App),
        }).$mount('#app')
    }
})
