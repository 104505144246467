<template>
    <div class="mt-5 mb-5">
        <FormulateForm
            v-model="form"
            name="medicationForm"
            @validation="validation = $event"
            @submit="submit"
        >
            <FormulateInput
                type="group"
                name="medication"
                :repeatable="true"
                add-label="+ Add Medication"
                class="max-w-screen-lg mx-auto"
            >
                <template v-slot:default="groupProps">
                    <div class="medication ">
                        <div
                            class="grid grid-cols-2 gap-4 mx-auto max-w-screen-lg mb-10 "
                        >
                            <FormulateInput
                                name="tradeName"
                                label="Drug (Trade Name)"
                                placeholder="Drug (Trade Name)"
                                help="Type to search for drug names"
                                type="drug"
                                :group-index="groupProps.index"
                                data-cypress="form.input.drugAutocomplete"
                                @select="addActiveSubstanceToField"
                            />

                            <FormulateInput
                                name="activeSubstance"
                                label="Active Substance"
                                placeholder="Active Substance"
                                help="e.g. Valproic Acid, Sodium Valproate etc."
                            />

                            <FormulateInput
                                name="dosagePerIntake"
                                label="Dosage per Intake (mg)"
                                placeholder="Dosage"
                                step="any"
                                min="0"
                                type="number"
                            />
                            <div
                                class="intake flex -ml-2 text-secondary-700 text-center"
                            >
                                <div class="px-2">
                                    <label
                                        for="intakeMorning"
                                        class="block mb-2"
                                    >
                                        <font-awesome-icon
                                            :icon="['fas', 'sunrise']"
                                    /></label>
                                    <FormulateInput
                                        name="intakeMorning"
                                        type="number"
                                        help="Morning"
                                        min="0"
                                        value="0"
                                        class="centered"
                                    />
                                </div>
                                <div class="px-2">
                                    <label
                                        for="intakeMidday"
                                        class="block mb-2"
                                    >
                                        <font-awesome-icon
                                            :icon="['fas', 'sun']"
                                    /></label>
                                    <FormulateInput
                                        name="intakeMidday"
                                        help="Midday"
                                        type="number"
                                        min="0"
                                        value="0"
                                        class="centered"
                                    />
                                </div>
                                <div class="mx-2">
                                    <label
                                        for="intakeEvening"
                                        class="block mb-2"
                                    >
                                        <font-awesome-icon
                                            :icon="['fas', 'sunset']"
                                            class="mr-2"
                                    /></label>
                                    <FormulateInput
                                        name="intakeEvening"
                                        help="Evening"
                                        type="number"
                                        min="0"
                                        value="0"
                                        class="centered"
                                    />
                                </div>
                                <div class="px-2">
                                    <label for="intakeNight" class="block mb-2">
                                        <font-awesome-icon
                                            :icon="['fas', 'moon']"
                                            class="mr-2"
                                    /></label>
                                    <FormulateInput
                                        name="intakeNight"
                                        help="Night"
                                        type="number"
                                        min="0"
                                        value="0"
                                        class="centered"
                                    />
                                </div>
                            </div>

                            <div>
                                <span class="text-lg text-gray-600">
                                    <font-awesome-icon
                                        :icon="['fas', 'poll-h']"
                                        class="mr-2"
                                    />Total Dosage per Day:
                                    {{
                                        getDosagePerDay(groupProps.index)
                                    }}&nbsp;mg</span
                                >
                            </div>
                        </div>
                    </div>
                </template>
            </FormulateInput>
            <div class="flex justify-end max-w-screen-lg mx-auto pr-12">
                <basic-button
                    class="mr-5"
                    color="gray"
                    data-cypress="patient.medication.back"
                    @click="back"
                    >Back</basic-button
                >
                <basic-button
                    :loading="patientLoading"
                    type="submit"
                    icon="check"
                    :disabled="validation.hasErrors"
                    data-cypress="patient.medication.submit"
                    >{{ saveText }}</basic-button
                >
            </div>
        </FormulateForm>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import _ from 'lodash'
import patientDataForm from '@/utils/mixins/patientDataForm'
import { toJson } from 'helpers/common'

/**
 * @group MOLECULES
 * This is a form to add medication to a patient entry
 */
export default {
    // This mixin is very important. It reuses methods for `back()` and `save()`
    mixins: [patientDataForm],
    inheritAttrs: true,
    data() {
        return {
            form: { medication: [] },
            validation: {},
            dosagePerDay: [],
        }
    },
    computed: {
        allMedicationData() {
            let allMedicationData = this.form.medication

            _.forEach(this.dosagePerDay, function(item, index) {
                if (allMedicationData[index]) {
                    allMedicationData[index]['dosagePerDay'] = item
                }
            })

            return allMedicationData
        },

        ...mapState('patient', {
            currentPatient: state => state.currentPatient,
        }),
    },
    watch: {
        'currentPatient.medication': {
            handler: function(val = [], oldVal = []) {
                if (val.length !== oldVal.length) {
                    this.form.medication = [...this.currentPatient.medication]
                }
            },
            immediate: true,
        },
    },
    methods: {
        // @vuese
        // Calcuates the dosage per day. I admit, the code is ugly. But VueFormulate was a pain in the ass. An I neded to make it more readable. Sorry.
        // @arg index
        getDosagePerDay(index) {
            if (Number.isInteger(index)) {
                let dosagePerDay = 0

                const element = this.form.medication[index]

                const intakeMorning =
                    this.getIntake(element, 'intakeMorning') || 0
                const intakeMidday =
                    this.getIntake(element, 'intakeMidday') || 0
                const intakeEvening =
                    this.getIntake(element, 'intakeEvening') || 0
                const intakeNight = this.getIntake(element, 'intakeNight') || 0
                const dosagePerIntake =
                    this.getIntake(element, 'dosagePerIntake') || 0

                // Set dosage per day bases intakeAmount * (sum of intakeEvents)
                dosagePerDay =
                    dosagePerIntake *
                    (intakeMorning + intakeMidday + intakeEvening + intakeNight)

                dosagePerDay = _.round(dosagePerDay, 3)

                // Set Data
                this.dosagePerDay[index] = dosagePerDay

                // Return Value
                return dosagePerDay
            }
        },

        // Sets the active substance based on the selected search entry
        addActiveSubstanceToField(entry, index) {
            // Sets the value on the medication-item
            this.form.medication[index] = {
                ...this.form.medication[index],
                activeSubstance: entry.activeSubstance,
            }
        },

        // @vuese
        // String to Number conversion. Input fields always return STRING!
        getIntake(object, string) {
            return _.toNumber(_.get(object, string, 0))
        },
        // @vuese
        // Submit Form Data and save it to patient
        // Read more: https://vueformulate.com/guide/forms/#submitting-forms
        submit() {
            this.addPatientData(toJson(this.form)).then(() => {
                this.save()
            })
        },
        ...mapActions('patient', ['addPatientData']),
    },
}
</script>

<style lang="scss" scoped>
.centered {
    text-align: center !important;

    & > input {
        text-align: center !important;
    }
}
</style>
