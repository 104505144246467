import _ from 'lodash'
const filters = function(Vue) {
    Vue.filter('currency', function(value) {
        return Number(value).toLocaleString('de-DE')
    })

    // Shorten a number to 2 fixed decimals
    Vue.filter('round', function(value) {
        if (!value) return 0

        value = parseFloat(value)
        return _.round(value, 2)
    })

    // Return the ful INT value
    Vue.filter('integer', function(value) {
        if (!value) return ''
        value = parseInt(value)
        return _.round(value)
    })

    // dosage is in miligram, but multiplied by 100 to avoid floats,
    // so we need to properly change the scale
    Vue.filter('dosage', function(value) {
        if (!value) return ''
        value = parseInt(value)
        return _.round(value / 100, 2)
    })

    // Format seoconds to timestamp
    Vue.filter('datetime', function(secs) {
        if (!secs) return 0

        // If the entire Firestore Timestamp Object was used in the filter
        if (_.has(secs, 'seconds')) {
            secs = secs.seconds
        }
        secs = parseInt(secs)

        let date = new Date(secs * 1000)
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }
        return date.toLocaleTimeString('en-US', options)
    })
    // Format seoconds to timestamp
    Vue.filter('date', function(secs) {
        if (!secs) return 0

        // If the entire Firestore Timestamp Object was used in the filter
        if (_.has(secs, 'seconds')) {
            secs = secs.seconds
        }

        secs = parseInt(secs)

        let date = new Date(secs * 1000)
        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        }
        return date.toLocaleDateString('en-Us', options)
    })

    // Format seoconds to timestamp
    Vue.filter('time', function(secs) {
        if (!secs) return 0

        if (_.has(secs, 'seconds')) {
            secs = secs.seconds
        }

        secs = parseInt(secs)
        let date = new Date(secs * 1000)

        return date.toLocaleTimeString('en-US')
    })

    // Format a DBI gender field
    Vue.filter('gender', function(value) {
        if (!value) return ''

        switch (value) {
            case 'm':
                return 'Male'
            case 'f':
                return 'Female'
            case 'x':
                return 'Other'
            default:
                return 'Not set'
        }
    })
    // Format Date as Age
    Vue.filter('age', function(data) {
        if (_.isUndefined(data)) return 0

        const seconds = data.seconds

        let birthday = new Date(seconds * 1000)

        var ageDifMs = Date.now() - birthday.getTime()
        var ageDate = new Date(ageDifMs) // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970)
    })
}

export default filters
