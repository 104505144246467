<template>
    <div class="md:mx-auto md:w-3/4 w-full" data-cypress="patient.checkIn">
        <div
            class="shadow-lg border-2 rounded-lg border-gray-300 pb-4  sm:pb-10 mx-2 min-h-32"
        >
            <div class="flex flex-wrap mb-10">
                <a
                    class="tab id block rounded-tl-lg"
                    :class="{ active: checkInTab }"
                    data-cypress="patient.tab.checkIn"
                    @click="toggleTab"
                >
                    Check-in Patient
                </a>
                <a
                    class="tab manual block rounded-tr-lg"
                    :class="{ active: !checkInTab }"
                    data-cypress="patient.tab.registerNew"
                    @click="toggleTab"
                >
                    Register New Patient
                </a>
            </div>
            <h3
                class="text-2xl font-bold text-center my-6 text-secondary-700"
                data-cypress="patient.checkIn.title"
            >
                <template v-if="checkInTab">Check-in Patient</template>
                <template v-else>Register New Patient</template>
            </h3>
            <div class="my-2 mx-2 md:mx-10 lg:mx-24  xl:mx-48 px-4">
                <template v-if="checkInTab"><PatientIdCheckin /></template>
                <template v-else><PatientRegistration /></template>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * @group ORGANISMS
 * A box that renders PatientRegistration and PatientCheckin. Has a tab bar to switch between.
 */
export default {
    data() {
        return {
            checkInTab: true,
        }
    },
    methods: {
        toggleTab() {
            this.checkInTab = !this.checkInTab
        },
    },
}
</script>
<style lang="scss" scoped>
.tab {
    @apply w-1/2  text-secondary-700 p-4 text-center no-underline bg-gray-200;
}

.tab:hover {
    cursor: pointer;
}

.tab.active {
    @apply bg-white font-bold;
}
</style>
