<template>
    <div>
        <FormulateForm
            v-model="form"
            class="checkin-form"
            @validation="validation = $event"
            @submit="submit"
        >
            <FormulateInput
                v-model="form.patientId"
                tabindex="1"
                type="autocomplete"
                label="Patient ID"
                autocomplete="no"
                name="patientId"
                :options="allPatients"
                placeholder="Search for Patient ID"
                bail
                allow-1password="no"
                validation="bail|required|max:10|min:10|validPatientId|inDataset"
                :validation-messages="{
                    validPatientId: 'This ID is not a valid DBI PatientID',
                    inDataset: 'The ID was not found in our Database',
                }"
                :validation-rules="{
                    inDataset: ({ value }) => allPatientIds.includes(value),
                }"
                data-cypress="patient.checkIn.input"
                @enter="submit()"
            />
            <div v-if="!validation.hasErrors" class="text-green-600 font-bold">
                Selected Patient: {{ form.patientId }}
            </div>

            <div class="flex justify-end">
                <FormulateInput
                    type="submit"
                    tabindex="2"
                    label="Check-in"
                    :disabled="validation.hasErrors"
                    data-cypress="patient.checkIn.submit"
                />
            </div>
        </FormulateForm>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
/**
 * @group MOLECULES
 * Checkin form to search for a patient based on PatientID and check-in
 */
export default {
    data() {
        return { form: { patientId: null }, validation: {} }
    },

    computed: {
        ...mapState({
            allPatients: state => state.patient.allPatients,
            allPatientIds: state => state.patient.allPatientIds,
        }),
    },
    mounted() {
        this.getAllPatients()
    },

    methods: {
        // @vuese
        // The submit methods handles the form submit. It extracts the patient ID and calls checkInPatient() on the VUEX Store
        submit() {
            const patientId = this.form.patientId
            this.checkInPatient(patientId)
                .then(() => {
                    // Add ID to Route
                    this.$router.replace({
                        name: 'Dashboard',
                        params: { patientId: patientId },
                    })
                })
                .catch(err => {
                    // Error while checkin
                    this.$toast.error(err)
                })
        },

        ...mapActions('patient', [
            'getAllPatients', // -> this.hasActivePatient
            'checkInPatient',
        ]),
    },
}
</script>
