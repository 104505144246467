<template>
    <div data-cypress="patient.registration">
        <FormulateForm
            :values="form"
            class="register-form"
            error-behavior="live"
            @validation="validation = $event"
            @submit="submit"
            @enter="submit"
        >
            <FormulateInput
                type="text"
                label="First Name"
                name="firstname"
                placeholder="First Name"
                validation="bail|required|min:2,length|alpha"
                data-cypress="dasda"
            />

            <FormulateInput
                type="text"
                label="Last Name"
                autocomplete="no"
                name="lastname"
                placeholder="Last Name"
                validation="bail|required|min:2,length"
            />

            <FormulateInput
                type="date"
                label="Birthdate"
                name="birthday"
                placeholder="dd-mm-YYYY"
                validation="bail|required|before|date"
            />

            <FormulateInput
                type="text"
                label="Birthplace"
                name="placeOfBirth"
                placeholder="Birthplace"
                validation="bail|required|min:2,length"
            />

            <FormulateInput type="hidden" name="country" />

            <div class="flex justify-end">
                <FormulateInput
                    type="submit"
                    label="Register and Check-in"
                    data-cypress="patient.registration.submit"
                    :disabled="validation.hasErrors"
                />
            </div>
        </FormulateForm>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

/**
 * @group MOLECULES
 * Register a new Patient based on DBI's custom logic.
 */
export default {
    data() {
        return {
            form: {
                firstname: null,
                lastname: null,
                country: 'CH',
                birthday: null,
                placeOfBirth: null,
            },
            validation: {},
        }
    },

    computed: {
        ...mapGetters('settings', [
            'getCountry', //also supports payload `this.nameOfAction(amount)`
        ]),
    },
    mounted() {
        if (this.getCountry) {
            this.form.country = this.getCountry
        }
    },

    methods: {
        // @vuese
        // Calls registerAndCheckIn() in Vuex and pushes the router to `Dashboard`. Toast will be shown on error.
        // @arg data
        submit(data) {
            this.registerAndCheckIn(data)
                .then(patient => {
                    //patient created (patient as payload)
                    this.$toast.success(`Created Patient ${patient.patientId}`)
                    // Redirect
                    this.$router
                        .push({
                            name: 'Dashboard',
                            params: { patientId: patient.patientId },
                        })
                        .catch(() => {
                            // ERROR --> do nothing
                        })
                })
                .catch(err => {
                    //error
                    this.$toast.error(
                        'Error while trying to register patient. Please try again.'
                    )
                    this.$toast.error(err)
                })
        },

        ...mapActions('patient', [
            'registerAndCheckIn', // -> this.registerAndCheckIn
        ]),
    },
}
</script>
