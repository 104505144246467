/*
|-------------------------------------------------------------------------------
| VUEX MODULE
|-------------------------------------------------------------------------------
| Authentication Events
*/
import router from '@/router'
import { fb } from '@/services/firebase'
import { listAllActionsOfStore, listAllGettersOfStore } from 'helpers/common'
import _ from 'lodash'

const mutations = {
    SET_CURRENT_USER: 'SET_CURRENT_USER',
    UPDATE_PROFILE: 'UPDATE_PROFILE',
    VERIFY_EMAIL: 'VERIFY_EMAIL',
}

export const user = {
    namespaced: true,

    state: {
        user: {},
    },

    mutations: {
        // Stores a user object from firebase in the state store
        [mutations.SET_CURRENT_USER](state, user) {
            state.user = user
        },
    },

    // actions
    actions: {
        setCurrentUser({ commit, dispatch }, user) {
            commit(mutations.SET_CURRENT_USER, user)

            // Load settings
            dispatch('settings/getSettings', user, { root: true })
                .then(() => {})
                .catch(err => {})
        },

        getCurrentUser({ commit }) {
            const user = fb.auth().currentUser
            // Reset the process
            commit(mutations.SET_CURRENT_USER, user)
            return user
        },
    },

    getters: {
        // hasEstates: (state, getters)
        //doneTodosCount: (state, getters) => {
        isLoggedIn: state => {
            return state.user !== null ? true : false
        },

        currentUser: state => {
            return state.user !== null ? state.user : false
        },
    },

    watch: {
        // Watcher to check if logged in users have verified their email
        isLoggedIn(gotLoggedIn) {
            // console.log('Watch called', gotLoggedIn)
        },
    },
}

listAllActionsOfStore(user, 'user')
listAllGettersOfStore(user, 'user')

export default user
