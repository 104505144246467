<template>
    <default-app-template>
        <basic-headline>Patient Data Collection Completed</basic-headline>
        <div class="mt-20 text-primary-500 text-center ">
            <font-awesome-icon :icon="['fas', 'check-circle']" size="6x" />
        </div>
        <p class="text-center font-bold text-primary-700 mt-6  mx-auto w-96">
            Please finish by saving the measurement to the database and continue
            with the instruction video to prepare patient for breath
            measurement.
        </p>

        <div class="flex justify-center mt-10">
            <basicButton
                :loading="isLoading"
                :disabled="!hasActiveMeasurement"
                icon="check"
                icon-right
                data-cypress="patient.createMeasurement.submit"
                @click="saveAndContinue()"
                >Save and Continue</basicButton
            >
        </div>
    </default-app-template>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
    name: 'Create',
    computed: {
        ...mapState('measurement', {
            measurement: state => state.active,
        }),

        ...mapState('patient', {
            currentPatient: state => state.currentPatient,
        }),

        ...mapGetters('measurement', ['isLoading']),
        ...mapGetters('measurement', [
            'hasActiveMeasurement', // -> this.hasActivePatient
        ]),
    },

    mounted() {
        // Prevent false data if reload was performed - go back to NEW then
        if (!this.measurement.uuid) {
            this.$router.replace({
                name: 'NewMeasurement',
            })
        }
    },

    methods: {
        saveAndContinue() {
            // Copy Diagnoses
            if ('diagnoses' in this.currentPatient) {
                this.addData({ diagnoses: this.currentPatient.diagnoses })
            }

            // Copy Meds
            if ('medication' in this.currentPatient) {
                this.addData({ medication: this.currentPatient.medication })
            }

            // Start async
            const saveMeasurement = this.save()
            const savePatient = this.savePatient()

            // Wait for all Promises to resolve
            Promise.all([saveMeasurement, savePatient])
                .then(() => {
                    this.$toast.success('Successfully saved in Database.')
                    this.$router.push({
                        name: 'Explanation',
                    })
                })
                .catch(error => {
                    this.$toast.error(
                        'Unable to save Measurement & Patient in Database. Try again.'
                    )
                    this.$toast.error(error)
                })
        },

        // VUEX
        ...mapActions('measurement', [
            'save', // -> this.save()
            'addData',
        ]),

        ...mapActions('patient', [
            'savePatient', // -> this.save()
        ]),
    },
}
</script>
