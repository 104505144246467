<template>
    <div>
        <button @click="seed">Seed DB</button>
    </div>
</template>

<script>
import { db, fb, firebase } from '@/services/firebase'

/**
 * @group ORGANISMS
 * This is a simple datebase seeing components that creates two dummy users in Firestore
 */
export default {
    methods: {
        seed() {
            // console.log('Start seeding..')

            var patientMax = {
                firstname: 'Maximilan',
                lastname: 'Könnings',
                patientId: 'CH1188ESMA',
                country: 'CH',
                birthday: firebase.firestore.Timestamp.fromDate(
                    new Date('November 20, 1988')
                ),
                placeOfBirth: 'Essen',
                dataUsage: {
                    agreed: true,
                    version: 1,
                },
                lastUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            }
            db.collection('patients')
                .doc(patientMax.patientId)
                .set(patientMax)
                .then(function() {
                    console.log(
                        'Document successfully written: ',
                        patientMax.patientId
                    )
                })

            var patientSarah = {
                firstname: 'Sarah',
                lastname: 'Eichmannn',
                patientId: 'DE1191STSA',
                country: 'DE',
                birthday: firebase.firestore.Timestamp.fromDate(
                    new Date('November 29, 1991')
                ),
                placeOfBirth: 'Stuttgart',

                dataUsage: {
                    agreed: true,
                    version: 1,
                },
                lastUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            }
            db.collection('patients')
                .doc(patientSarah.patientId)
                .set(patientSarah)
                .then(function() {
                    console.log(
                        'Document successfully written: ',
                        patientSarah.patientId
                    )
                })
        },
    },
}
</script>
