<template>
    <div class="my-8 mx-16">
        <h3 class="text-lg text-center text-primary-700 font-bold mb-8 pb-0">
            Please upload files for breath analysis now.
        </h3>

        <div class="flex justify-between  space-x-16">
            <div class="w-1/2">
                <p
                    class="text-center text-gray-300 my-5"
                    :class="{ 'text-green-600': upload.neg.completed }"
                >
                    <font-awesome-icon
                        :icon="['fas', 'plus-circle']"
                        size="6x"
                    />
                </p>
                <DropzoneInput
                    label="Select positive RAW file to upload for analysis"
                    iontype="pos"
                />
            </div>
            <div class="w-1/2">
                <p
                    class="text-center text-gray-300 my-5"
                    :class="{ 'text-green-600': upload.neg.completed }"
                >
                    <font-awesome-icon
                        :icon="['fas', 'minus-circle']"
                        size="6x"
                    />
                </p>
                <DropzoneInput
                    label="Select negative RAW file to upload for analysis"
                    iontype="neg"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
/**
 * @group ORGANISMS
 *   A file uploader component that renders two uploaders for POS and NEG files
 */
export default {
    data() {
        return {}
    },
    computed: {
        ...mapState('measurement', {
            measurement: state => state.active,
            upload: state => state.upload,
        }),
    },
}
</script>
