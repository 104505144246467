import _ from 'lodash'

// Return Timestamp
export function dateFromString(string) {
    const parts = string.split('-')
    return new Date(parts[0], parts[1] - 1, parts[2])
}

export function calculatePatientId(patient) {
    // A sane patientId: DE1191STSA

    /*
    Suggestion:
        ISO-3166-2 Codes →  implemented in settings of operators (fallback CH)
        Birthdate (MMJJ)
        Place of Birth → first two letters
        First Name (Rufname) → first two letters
    */

    let patientId = null
    try {
        //   str.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        // 1. Add Country
        patientId = patient.country.toUpperCase()

        // 2. Add Birtday
        // @TODO: Use a date library
        const birthDate = dateFromString(patient.birthday)

        const MM =
            (birthDate.getMonth() < 9 ? '0' : '') + (birthDate.getMonth() + 1)
        const YY = birthDate
            .getFullYear()
            .toString()
            .substr(2, 2)

        patientId += MM
        patientId += YY

        // 3. Place of Birth (before deburr and remove whitespaces)
        let placeOfBirth = patient.placeOfBirth
        placeOfBirth = _.deburr(placeOfBirth)
        placeOfBirth = placeOfBirth.replace(/\s/g, '')
        patientId += placeOfBirth.substr(0, 2).toUpperCase()

        // 4. Firstname (before deburr and remove whitespaces)
        let firstname = _.deburr(patient.firstname)
        firstname = firstname.replace(/\s/g, '')
        patientId += firstname.substr(0, 2).toUpperCase()
    } catch (err) {
        return null
    }
    return patientId
}
