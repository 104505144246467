<template>
    <div>
        <basic-button
            v-if="!confirmed && !confirming"
            color="red"
            class="block mx-auto"
            @click="confirm()"
            >Delete</basic-button
        >

        <div v-if="confirming">
            <p class="font-bold text-gray-800">Are you sure?</p>
            <small class="text-gray-700 my-2 block"
                >This cannot be undone.</small
            >
            <p class="mb-2">
                <basic-button
                    color="gray"
                    class="block mx-auto"
                    @click="confirm()"
                    >No</basic-button
                >
            </p>
            <p>
                <basic-button
                    color="red"
                    icon="trash"
                    class="block mx-auto"
                    @click="deleteItem()"
                    >Yes, delete.</basic-button
                >
            </p>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

/**
 * @group MOLECULES
 *  Renders a delete box with user confirmation to delete a measurement
 */
export default {
    props: {
        // UUID of the measurement that should be deleted.
        uuid: {
            type: String,
            // The measruement that is in the param of the route
            default: null,
        },
    },

    data() {
        return {
            confirmed: false,
            confirming: false,
        }
    },
    methods: {
        confirm() {
            console.log('confirm?', this.confirming)
            this.confirming = !this.confirming
        },

        deleteItem() {
            let uuid = this.uuid

            if (!this.uuid || this.uuid === undefined) {
                uuid = this.$route.params.uuid
            }

            this.delete(uuid)
                .then(() => {
                    // ERROR
                    this.$toast.success('Successfully deleted measurement!')
                    this.$router.push({ name: 'Dashboard' })
                })
                .catch(err => {
                    // ERROR
                    this.$toast.error('Could not delete measurement: ', err)
                })
        },

        ...mapActions('measurement', ['delete']),
    },
}
</script>
