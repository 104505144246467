<template>
    <div
        :class="
            `formulate-input-element formulate-input-element--${context.type} relative`
        "
        :data-type="context.type"
    >
        <input
            v-model="context.model"
            type="text"
            v-bind="context.attributes"
            autocomplete="off"
            @keyup="openSearchbox"
            @keyup.down.prevent="increment()"
            @keyup.up.prevent="decrement()"
            @keydown.enter.prevent="enter()"
            @blur="context.blurHandler"
        />

        <transition name="fade">
            <p
                v-if="isLoading"
                class="absolute right-0"
                style="top: 1.1rem; right: 1rem;"
            >
                <font-awesome-icon
                    :icon="['fas', 'circle-notch']"
                    class="animate-spin text-secondary-300 "
                />
            </p>
        </transition>
        <ul
            v-if="searchResults.length"
            class="formulate-input-dropdown p-2 bg-gray-100 shadow-lg absolute w-full rounded-bl-lg rounded-br-lg mb-10 z-1000"
            data-cypress="patient.medication.autocomplete.list"
        >
            <li
                v-for="(entry, index) in searchResults"
                :key="index"
                class="text-gray-700 cursor-pointer  hover:bg-primary-100  hover:font-bold px-2 py-2 rounded-lg my-1"
                :class="{
                    'bg-gray-200': index % 2 == 0,
                    'bg-primary-100 font-bold': selectedIndex == index,
                }"
                @click.stop="selectEntry(index)"
                @mouseover="selectedIndex = index"
            >
                <span>{{ entry[displayKey] }}</span>
            </li>

            <li v-if="isLoading" class="text-gray-500 text-sm py-1 text-center">
                Searching...
            </li>
            <li
                v-else
                class="text-gray-500 text-sm py-1 text-center"
                data-cypress="patient.medication.autocomplete.results.item"
            >
                {{ numberOfHits }} results found.
            </li>
        </ul>
    </div>
</template>

<script>
// Import only the search-sdk
import algoliasearch from 'algoliasearch/lite'
import AutocompleteMixin from './AutocompleteMixin'

/**
 * @group VUE-FORMULATE PLUGINS
 * Drug Autocomplete Plugin using custom a custom Algolia Endpoints
 */
export default {
    mixins: [AutocompleteMixin],

    props: {
        // Context Object provided by Vue Formulate
        context: {
            type: Object,
            required: true,
        },
        // Index of the current group - needed if the input is used in a VueFormulate group
        groupIndex: {
            type: Number,
            required: false,
            default: null,
        },
        // The key of the search result object that should be just as a display value
        displayKey: {
            type: String,
            default: 'title',
        },
    },
    data() {
        return {
            numberOfHits: 0,
        }
    },

    computed: {
        algoliaAppId() {
            return process.env.VUE_APP_DRUG_ALGOLIA_APP_ID
                ? process.env.VUE_APP_DRUG_ALGOLIA_APP_ID
                : Error('No Algolia App ID provided in .env File')
        },

        algoliaEndpointUrl() {
            return process.env.VUE_APP_DRUG_ALGOLIA_ENDPOINT
                ? process.env.VUE_APP_DRUG_ALGOLIA_ENDPOINT
                : Error('No Algolia Endpoint URL provided in .env File')
        },

        algoliaSearchKey() {
            return process.env.VUE_APP_DRUG_ALGOLIA_SEARCH_API_KEY
                ? process.env.VUE_APP_DRUG_ALGOLIA_SEARCH_API_KEY
                : Error('No Algolia Search Key provided in .env File')
        },

        algoliaSearchIndex() {
            return process.env.VUE_APP_ALGOLIA_SEARCH_INDEX
                ? process.env.VUE_APP_ALGOLIA_SEARCH_INDEX
                : Error('No Algolia Search Index provided in .env File')
        },
    },

    methods: {
        // @vuese
        // Calls the API using Axios. Endpoint can be defined in by env: 'VUE_APP_ICD_SEARCH_ENDPOINT'
        // @arg query
        getSearchResult(query) {
            // Send a GET request
            this.isLoading = true

            const client = algoliasearch(
                this.algoliaAppId,
                this.algoliaSearchKey
            )

            const index = client.initIndex(this.algoliaSearchIndex)

            // DOCS: https://www.algolia.com/doc/guides/getting-started/quick-start/tutorials/quick-start-with-the-api-client/javascript/?client=javascript#install

            index
                .search(query, { hitsPerPage: this.maxItems })
                .then(({ hits, nbHits }) => {
                    this.searchResults = hits
                    this.numberOfHits = nbHits
                    this.isLoading = false
                })
                .catch(error => {
                    // Fire when there is an error in the API Call
                    this.$emit('searcherror', error)
                    this.isLoading = false
                })
        },

        // @vuese
        // Selects an entry from search results and closes the box
        // @arg index
        selectEntry(index) {
            const entry = this.searchResults[index]
            if (entry) {
                this.context.model = entry[this.displayKey]
                this.$emit('select', entry, this.groupIndex)
                this.closeSearchbox()
            }
        },
    },
}
</script>
